<template>
  <div class="album-template">
    <div class="album-template-list">
      <div v-if="activeType === 'bookType'" class="text-left">
        <h4>請選擇您要製作的書籍</h4>
        <div class="album-template-item" v-for="(item, index) in bookList" :key="index">
          <div class="template-cover fullsize">
            <cover-holder :src="item.cover" :height="bannerHeight" />
          </div>
          <div class="template-info">
            <h4>{{item.title}}</h4>
            <p>{{item.subtitle}}</p>
            <a :href="item.path">
              <button class="btn primary">
                開始製作 <i class="fa fa-chevron-right"></i>
              </button>
            </a>
          </div>
        </div>
      </div>
      <template v-else>
        <template v-if="templateLoaded">
          <div v-for="(template, index) in templateList" :key="index" class="album-template-item">
            <div class="template-cover">
              <cover-holder :src="template.coverImage" :height="template.coverHeight" :class="template.bookType"/>
            </div>
            <div class="template-info">
              <div class="template-info__title">{{template.title}}</div>
              <p>{{template.description}}</p>
              <div>
                <router-link :to="{path: '/setup', query: {type: template.tid}}">
                  <button class="btn primary">選擇模板</button>
                </router-link>
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="loading-icon"></div>
          <p class="text-gray">加載中</p>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import CoverHolder from '../components/CoverHolder'
import generateCover from '../_utils/generateCover'
import { mapGetters } from 'vuex'
import api from 'src/api'
export default {
  name: 'album-template',
  components: { CoverHolder },
  data: () => ({
    templateLoaded: false,
    templateList: [],
    bannerHeight: 90,
    coverHeight: 141.1,
    activeType: 'other',
    bookList: [
      {
        cover: require('../../static/images/product/diarybook-6-thumb.png'),
        name: 'diarybook',
        title: '時光書',
        subtitle: '一鍵導入你的“臉書”內容，自動排版',
        path: '/app/shelf'
      }
      // {
      //   cover: require('../../static/images/product/photobook-3-thumb.png'),
      //   name: 'photobook',
      //   title: '相片書',
      //   subtitle: '用相片珍藏回憶',
      //   path: '/templates'
      // }
    ]
  }),
  computed: {
    ...mapGetters(['user'])
  },
  created () {
    const { type, source } = this.$route.query
    if (type) {
      this.activeType = type
    } else {
      this.fetchTemplateList(source)
    }
  },
  methods: {
    fetchTemplateList (source = 'photobook') {
      const requestHandler = {
        photobook: api.fetchPhotoTemplates,
        calendar: api.fetchCalendarTemplates
      }
      const request = requestHandler[source]
      request().then(data => {
        const templates = (data.filter(item => item.available)).map(template => {
          template.bookType = source
          if (source === 'calendar') {
            return {
              ...template,
              coverHeight: 72.8,
              coverImage: 'https://canvas.xinshu.me/generate/2020-h-traditional.001?size=480'
            }
          } else {
            const { defaultCover, defaultDesignType } = template
            const design = {
              cover: defaultCover,
              template: defaultDesignType,
              title: `${this.user.nickname}'s PhotoBook`,
              author: this.user.nickname
            }
            return {
              ...template,
              coverHeight: defaultDesignType.includes('magazine') ? 77 : 141.1,
              coverImage: generateCover({ design })
            }
          }
        })
        this.templateList = templates
        this.templateLoaded = true
      })
    },
    checkTemplate () {
      if (this.$route.query.source === 'calendar') {
        window.location.href = `https://ipastbook.com/app/design/album/?aid=705f9269adec&type=calendar&from=${window.location.href}`
        return
      }
      window.location.href = `https://ipastbook.com/app/design/album/?aid=924236a1110a&from=${window.location.href}`
    },
    selectTemplate (template) {
      if (this.$route.query.source === 'calendar') {
        this.$router.push({ path: '/setup?type=traditional&binding=2020-h-traditional' })
        return
      }
      const { tid, bookType } = template
      this.$router.push({ path: '/setup', query: { tid, bookType } })
    }
  }
}
</script>

<style lang="scss">
  .album-template {
    text-align: center;
    &-list {
      padding: 16px;
      max-width: 540px;
      margin: 0 auto;
    }
    &-item {
      display: flex;
      padding: 1em;
      box-shadow: 0 2px 10px 0 rgba(0,0,0,0.1);
      border-radius:12px;
      margin-bottom: 1.5em;
      text-align: left;
      overflow: hidden;
      .template-cover {
        min-height: 120px;
        display: flex;
        align-items: center;
        flex: 0 0 30%;
        margin-right: 20px;
        width: 100px;
        &.fullsize {
          min-height: 160px;
          flex: 0 0 43%;
          margin: -16px;
          margin-right: 16px;
        }
      }
      .template-info {
        flex: 1;
        &__title {
          font-weight: 500;
          font-size: 15px;
        }
        p {
          line-height: 1.25;
          font-size: 13px;
          margin: .75em 0;
        }
      }
    }
  }
</style>
