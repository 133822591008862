<template>
  <div class="container-fluid orders">
    <div class="trade" :data-loading="$t('common.toast.loading.title')">
      <div class="row">
        <div class="col-xs-12 col-sm-6">
          <order-info :book="bookInfo" :quantity="order.quantity"></order-info>
          <address-info :address="address"></address-info>
        </div>
        <div class="col-xs-12 col-sm-6 ">
          <pay-methods :method="order.payMethod" @change="changeMethods"></pay-methods>
          <!--快递-->
          <div class="form-group">
            <label>{{$t('common.label.express')}}</label>
            <p>
              <label class="active">
                <input type="radio" name="delivery"
                       value="3-day-delivery" v-model="order.delivery">
                印刷 + 快遞（10個工作日）
              </label>
            </p>
          </div>
          <!--优惠券-->
          <div class="form-group" v-if="order.couponNo">
            <label>{{$t('common.text.coupon')}}</label>
            <p class="text-small text-primary">{{$t('trade.coupon.valid')}}</p>
          </div>
          <!--备注-->
          <div class="form-group">
            <label>{{$t('common.label.note')}}</label>
            <div class="row">
              <div class="col-xs-8">
                <textarea class="textarea" name="note"></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="text-right text-gray" v-if="!priceLoaded">{{$t('common.toast.loading.calculate')}}</div>
    <div class="trade-price" v-else>
      <div class="text-right">
        <p>
          <label>{{$t('trade.order.totalPrice')}}：
            <strong class="text-red" :class="{'text-line': order.couponNo}">
              {{symbol}}{{bookCost.toFixed(2)}}
            </strong>
          </label>
        </p>
        <p>
          <label>
            {{$t('trade.order.discount')}}：
            <template>
              - {{symbol}}{{discount.toFixed(2)}}
            </template>
          </label>
        </p>
        <p>
          <label>{{$t('trade.order.expressFee')}}：{{symbol}}{{deliveryCost.toFixed(2)}}</label>
        </p>
        <p>
          <label>{{$t('trade.order.tax')}}：{{symbol}}0.00</label>
        </p>
      </div>
      <div class="order-pay text-right">
        {{$t('trade.order.orderPrice')}}:
        <strong class="text-red total-cost">{{symbol}}{{totalCost}}</strong>
        <button class="btn primary" @click="initCheckout">{{$t('trade.order.payButton')}}</button>
      </div>
    </div>
    <!--stripe 支付台-->
    <modal :show="startPay" id="stripe-modal" ref="stripe">
      <stripe-modal slot="body" :price="totalCost"
                    @close="startPay = false"
                    @lack="startPay = false"
                    @paid="stripePaid"/>
    </modal>
    <!--loading 确认中-->
    <toast :message="paidLoading.text" :value="paidLoading.show" :lock="true" closeable>
      <div class="loading-icon"></div>
    </toast>
  </div>
</template>

<script>
import api from 'src/api/'
import load from 'src/_utils/load-script'
import OrderInfo from './OrderInfo.vue'
import AddressInfo from './AddressInfo.vue'
import PayMethods from './PayMethods.vue'
import StripeModal from './StripeModal.vue'
import priceCalculator from 'src/mixins/priceCalculator'
import currency from 'src/mixins/currency'
import { mapGetters } from 'vuex'
const order = {
  bid: '',
  addressId: '',
  quantity: '1',
  binding: 'hardback',
  printType: 'a5-waterfall-tweet',
  countryCode: 'TW',
  currency: 'TWD', // 货币符号
  delivery: '3-day-delivery',
  couponNo: '',
  payMethod: 'stripe',
  locale: 'zh-TW',
  tz: 8,
  note: '',
  payDevice: 'phone'
}

export default {
  name: 'trade',
  components: { OrderInfo, AddressInfo, PayMethods, StripeModal },
  mixins: [priceCalculator, currency],
  data () {
    return {
      address: {},
      bookInfo: {
        design: {
          title: '',
          author: '',
          template: 'type-1',
          cover: {
            imageUrl: '',
            color: '#fff'
          }
        },
        totalPage: 0
      },
      order: Object.assign({}, order),
      startPay: false,
      prices: {
        book: {},
        delivery: {}
      },
      priceLoaded: false,
      paidLoading: {
        text: '',
        show: false
      },
      activeValue: {
        type: 'discount',
        value: 1
      }
    }
  },
  computed: {
    ...mapGetters([
      'locale', 'language'
    ]),
    booksCost () { // 全部书籍原价
      if (!this.priceLoaded) {
        return 0
      }
      // 计算单品价格
      try {
        const priceMaps = this.prices.book[this.order.binding]
        const pageLimit = this.order.binding === 'blog' ? 20 : 50
        const page = this.bookInfo.totalPage < pageLimit ? pageLimit : this.bookInfo.totalPage
        let cost = (priceMaps.cover - 0) + (priceMaps.inner - 0) * page
        cost = cost * this.order.quantity
        return cost
      } catch (e) {
        console.error(e)
        return 0
      }
    },
    deliveryCost () {
      if (this.order.binding === 'pdf') {
        return this.prices.delivery['0-day-delivery'] - 0
      } else {
        return this.prices.delivery['3-day-delivery'] - 0
      }
    },
    discount () {
      try {
        if (this.activeValue.type === 'discount') {
          return ((100 - this.activeValue.value * 100) / 100) * this.booksCost
        } else {
          return this.activeValue.value
        }
      } catch (e) {
        console.error(e)
        return 0
      }
    },
    totalCost () {
      return (this.booksCost + this.deliveryCost - this.discount).toFixed(2)
    }
  },
  created () {
    const { bid } = this.$route.params
    const { addressId, binding, quantity, couponNo } = this.$route.query
    this.order.bid = bid
    this.order.payDevice = this.isMobile ? 'phone' : 'web'
    this.order.addressId = addressId || this.order.addressId
    this.order.binding = binding || this.order.binding
    this.order.quantity = quantity || this.order.quantity
    this.fetchBook(bid)
    this.fetchAddress(addressId)
    this.fetchPrices()
    if (couponNo) {
      this.checkCoupon(couponNo)
    }
    window.ga('require', 'ecommerce') // 加载电子商务插件
    this.postItem()
  },
  mounted () {
    this.initStripe()
  },
  methods: {
    // 提交交易信息
    postItem () {
      window.ga('ecommerce:addTransaction', {
        id: this.$route.params.bid,
        revenue: this.booksCost + this.deliveryCost,
        shipping: this.deliveryCost,
        tax: '0',
        currency: this.currency
      })
    },
    // 初始书籍
    fetchBook (bid) {
      return api.fetchBook(bid).then(data => {
        this.bookInfo = data
        return Promise.resolve(data)
      })
    },
    // 请求收件地址
    fetchAddress (addressId) {
      return api.fetchAddress(addressId).then(data => {
        this.address = data
      })
    },
    // 请求价格
    fetchPrices () {
      const locale = this.locale.toLowerCase()
      return api.fetchPrice(locale).then(data => {
        this.handleBackEndPrice(data).then(data => {
          this.prices = data
          this.priceLoaded = true
        })
      })
    },
    // 检查当前优惠券
    checkCoupon (couponNo) {
      return api.checkCoupon(couponNo).then(data => {
        if (!data || data.errors) {
          return
        }
        this.order.couponNo = couponNo || this.order.couponNo
        this.activeValue = {
          type: data.coupon.type,
          value: data.coupon.value
        }
      })
    },
    // 初始化 stripe
    initStripe () {
      if (window.Stripe) {
        return
      }
      load('https://js.stripe.com/v2/', (err, script) => {
        if (err) {
          this.$toast({
            message: this.$t('trade.toast.stripeInitFailed'),
            position: 'bottom'
          })
          return
        }
        api.fetchKey('stripe').then(data => {
          if (!data || data.errors) {
            const errorCode = data.errors ? data.errors.code : ''
            const message = errorCode ? this.$t('trade.toast.StripeKeyLoadedError') + errorCode : this.$t('trade.toast.StripeKeyLoadedError')
            this.$toast({
              message,
              position: 'bottom'
            })
            return
          }
          window.Stripe.setPublishableKey(data)
        })
      })
    },
    changeMethods (value) {
      this.order.payMethod = value
    },
    // stripe 获取 token 回调
    stripePaid (token) {
      this.startPay = false
      this.order.token = token
      this.initCheckout() // 再次确认支付
    },
    // 开始后端结算
    initCheckout () {
      if (this.order.payMethod === 'stripe' && !this.order.token) {
        this.startPay = true
        return
      }
      this.startCheckout()
    },
    // 后端数据交互
    startCheckout () {
      this.paidLoading = { text: this.$t('trade.toast.PaymentConfirming'), show: true }
      this.order.countryCode = this.locale
      this.order.currency = this.currency
      this.order.locale = this.language
      return api.postOrder(this.order).then(data => {
        this.paidLoading.show = false
        if (!data || data.errors) { // 支付错误提醒
          const message = data.errors ? data.errors.message + '<br/>' + '错误码：' + data.errors.code : '支付错误'
          this.$toast({
            message,
            position: 'bottom'
          })
          if (this.order.payMethod === 'stripe') {
            this.$router.push({
              name: 'order-status',
              params: data.orderId,
              query: { state: 'failed' }
            })
            return
          }
          return
        }

        if (this.order.payMethod === 'paypal' || this.order.payMethod === 'alipay') { // paypal 支付
          this.paidLoading = { text: this.$t('trade.toast.PayPalConfirming'), show: true }
          window.location.href = data.approvalUrl
          return
        }
        // stripe 支付处理
        this.$toast({
          message: this.$t('trade.toast.PaymentSucceed'),
          position: 'bottom'
        })
        // 支付成功
        window.ga('ecommerce:addItem', {
          id: data.orderId,
          name: this.bookInfo.design.title,
          category: this.order.binding,
          quantity: this.order.quantity,
          currency: this.currency
        })
        // GA监控支付，提交电子商务
        window.ga('ecommerce:send')
        this.$router.push({
          name: 'order-status',
          params: { orderId: data.orderId }
        })
      })
    }
  }
}
</script>

<style lang="scss">
@import '../../../styles/layout/Trade.scss';
</style>
