<template>
  <div class="cell" :class="{'input': field}" @click="$emit('click')" >
    <span class="cell-mask" v-if="isLink"></span>
    <div class="cell-left">
      <slot name="left"></slot>
    </div>
    <div class="cell-wrapper">
      <div class="cell-title" v-if="title || $slots.title">
        <slot name="icon">
          <i v-if="icon" class="fa" :class="'fa-' + icon"></i>
        </slot>
        <slot name="title">
          <span class="cell-text">{{title}}</span>
          <span v-if="label" class="cell-label">{{label}}</span>
        </slot>
      </div>
      <div class="cell-value" :class="{ 'is-link' : isLink }">
        <slot>
          <span>{{value}}</span>
        </slot>
      </div>
      <i v-if="isLink" class="cell-allow-right"></i>
    </div>
    <div class="cell-right">
      <slot name="right"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'cell',
  props: {
    title: {
      type: String,
      default: ''
    },
    icon: String,
    label: String,
    text: String,
    isLink: Boolean,
    value: Boolean,
    field: Boolean
  },
  data () {
    return {}
  },
  created () {}
}
</script>

<style lang="scss">
  .cell {
    background-color:#fff;
    box-sizing:border-box;
    color:inherit;
    min-height:48px;
    display:block;
    overflow:hidden;
    position:relative;
    text-decoration:none;
    &.input {
      .cell-title {
        flex: none;
        width: 105px;
      }
      .cell-value {
        flex: 1;
      }
    }
  }
  .cell img {
    vertical-align:middle;
  }
  .cell:first-child .cell-wrapper {
    background-origin:border-box;
  }
  .cell:last-child {
    background: {
      image:linear-gradient(0deg, #d9d9d9, #d9d9d9 50%, transparent 50%);
      size:100% 1px;
      repeat:no-repeat;
      position:bottom;
    };
  }
  .cell-wrapper {
    background: {
      image:linear-gradient(180deg, #d9d9d9, #d9d9d9 50%, transparent 50%);
      size: 120% 1px;
      repeat: no-repeat;
      position: top left;
      origin: content-box;
    };
    align-items: center;
    box-sizing: border-box;
    display: flex;
    font-size: 16px;
    line-height: 1;
    min-height: inherit;
    overflow: hidden;
    padding: 0 10px;
    width: 100%;
  }
  .cell-mask {
    &:after {
      background-color:#000;
      content:" ";
      opacity:0;
      top:0;
      right:0;
      bottom:0;
      left:0;
      position:absolute;
    }
  }
  .cell-mask:active::after {
    opacity:.1;
  }
  .cell-text {
    vertical-align: middle;
  }
  .cell-label {
    color: #888;
    display: block;
    font-size: 12px;
    margin-top: 6px;
  }
  .cell-title {
    flex: 1;
  }
  .cell-value {
    color: #888;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    &.is-link {
      margin-right:24px;
    }
  }
  .cell-left {
    position: absolute;
    height: 100%;
    left: 0;
    transform: translate3d(-100%, 0, 0);
  }
  .cell-right {
    position: absolute;
    height: 100%;
    right: 0;
    top: 0;
    transform: translate3d(100%, 0, 0);
  }
  .cell-allow-right::after {
    border: solid 2px #c8c8cd;
    border-bottom-width: 0;
    border-left-width: 0;
    content: " ";
    top:50%;
    right:20px;
    position: absolute;
    width:5px;
    height:5px;
    transform: translateY(-50%) rotate(45deg);
  }
  .cell-textarea {
    resize: none;
    font-size: 14px;
    height: 80px;
    display: block;
    width: 100%;
    border: none;
    border-radius: 0;
    background: none;
    outline: none;
    padding: 8px 0;
  }
  .check {
    font-size: 24px;
  }
</style>
