<template>
  <div class="agent-card">
    <div class="agent-withdraw">
      <div class="agent-withdraw-hero">
        <div class="text-center" v-if="!dataLoaded">
          <div class="loading-icon"></div>
          <p class="mt-1">載入中...</p>
        </div>
        <div class="account-info-wrapper" v-else>
          <div v-if="accountInfo && !isAccountUpdating">
            <div class="account-info">
              <img src="../../../static/icons/paypal.png" alt="">
              <div>
                <h3>
                  提現至
                  <b>{{accountInfo.account}}</b>
                  <a href="javascript:;" @click="startUpdate" class="text-primary">更新</a>
                </h3>
                <p v-if="availableAmount">可提現金額：{{availableAmount.symbol}} {{availableAmount.avaliableMoney}}</p>
              </div>
            </div>
            <div>
              <button class="btn btn-lg primary btn-block" :disabled="isRequesting"
                      @click="startWithdraw">
                <i class="fa fa-spinner fa-spin" v-if="isRequesting"></i>
                確認全部提現
              </button>
            </div>
          </div>
          <div v-else>
            <h4>請完善提現賬戶</h4>
            <div class="account-form">
              <div class="form-group">
                <input class="form-control" type="text" placeholder="輸入PayPal賬戶"
                       v-model.trim="basicItem" @focus="isFocus = true" @blur="isFocus = false"/>
              </div>
              <div class="form-group" :class="{'has-error': !isValid && !isFocus}">
                <input class="form-control" @focus="isFocus = true" @blur="isFocus = false"
                       type="text" placeholder="再次輸入PayPal賬戶" v-model.trim="confirmItem">
                <span class="help-block text-small" v-show="!isValid && !isFocus">請確保輸入正確賬戶</span>
              </div>
              <div class="has-success">
                <div class="checkbox">
                  <label>
                    <input type="checkbox" v-model="isDefault">
                    設置為缺省返現賬戶
                  </label>
                </div>
              </div>
              <p class="text-center">
                <button class="btn primary" @click="confirmForm" :disabled="isAccountRequesting">
                  <i class="fa fa-spinner fa-spin" v-if="isAccountRequesting"></i>
                  {{accountInfo ? '更新返現賬戶' : '創建返現賬戶'}}
                </button>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="agent-withdraw-plane" v-show="accountInfo">
        <h3>提示</h3>
        <ol>
          <li>請確保賬號信息正確</li>
          <li>PayPal提現標準費率，3000美元及以下，每筆4.4% + 0.30 美元</li>
          <li><b>提現48小時到賬</b></li>
        </ol>
      </div>
    </div>
  </div>
</template>

<script>
import api from 'src/api'
import { mapGetters, mapActions } from 'vuex'
import { isNull } from 'lodash'
export default {
  name: '',
  data: () => ({
    dataLoaded: false,
    accountInfo: null,
    isAccountUpdating: false,
    basicItem: '',
    confirmItem: '',
    isDefault: false,
    activeItemId: '',
    isRequesting: false,
    isAccountRequesting: false,
    isFocus: false
  }),
  computed: {
    ...mapGetters(['availableAmount']),
    isValid () {
      return this.basicItem === this.confirmItem
    }
  },
  created () {
    this.fetchPayAccount()
    if (isNull(this.availableAmount)) {
      this.fetchDetail()
    }
  },
  methods: {
    ...mapActions(['setAvailableAmount']),
    fetchPayAccount () {
      api.fetchPayAccount().then(data => {
        this.dataLoaded = true
        if (!data.length) {
          return
        }
        const accountList = data.filter(item => item.isDefault)
        this.accountInfo = data.length ? accountList[0] : data[0]
      })
    },
    fetchDetail () {
      api.fetchAgentInfo().then(data => {
        this.dataLoaded = true
        const { avaliableMoney, totalMoney, discount, topRate } = data
        const infoData = {
          ...data,
          amount: avaliableMoney.toFixed(2),
          maxCount: totalMoney.toFixed(2),
          discount: Math.round(100 - discount * 100),
          topRate: Math.round(topRate * 100)
        }
        this.setAvailableAmount(infoData)
      })
    },
    confirmForm () {
      if (!this.basicItem || !this.confirmItem) {
        return
      }
      if (!this.isValid) return

      let request = null
      if (this.accountInfo) {
        request = this.updateAccount()
      } else {
        request = this.createAccount()
      }
      this.isAccountRequesting = true
      request.then(_ => {
        this.isAccountUpdating = false
        this.isAccountRequesting = false
      })
    },
    startUpdate () {
      const { isDefault, account } = this.accountInfo
      this.isAccountUpdating = true
      this.basicItem = account
      this.confirmItem = account
      this.isDefault = isDefault
    },
    updateAccount () {
      const id = this.accountInfo.accountId
      return api.updatePayAccount({ id, info: { account: this.basicItem, isDefault: this.isDefault } }).then(data => {
        this.accountInfo = data
      })
    },
    createAccount () {
      return api.createPayAccount({ account: this.basicItem, isDefault: this.isDefault }).then(data => {
        this.accountInfo = data
      })
    },
    startWithdraw () {
      this.$confirm({
        title: '返現申請提交後無法撤銷，確認返現',
        onOk: (modal) => {
          this.ensureWithdraw()
          modal.close()
        }
      })
    },
    ensureWithdraw () {
      this.isRequesting = true
      api.withdrawAll(this.accountInfo.accountId).then(data => {
        this.isRequesting = false
        if (data.errors) {
          this.$toast({
            message: data.errors.message
          })
          return
        }
        this.$router.back()
      })
    }
  }
}
</script>

<style lang="scss">
  .agent-withdraw {
    padding: .75rem;
    text-align: left;
    &-hero {
      background-color: #ffffff;
      padding: 2.5rem 1rem;
    }
    .account-info {
      margin-bottom: 1.75rem;
      display: flex;
      img {
        width: 50px;
        margin-right: 10px;
      }
      h3 {
        font-weight: 400;
        font-size: 14px;
        margin: 0;
        margin-bottom: .5em;
        b {
          font-size: 1.25em;
        }
      }
      a {
        margin-left: 8px;
      }
      &-wrapper {
        h4, p {
          margin-bottom: 1rem;
        }
        h4 {
          font-size: 16px;
          font-weight: 600;
          color: #666;
        }
      }
    }
    &-plane {
      margin-top: .5rem;
      padding: 1rem;
      h3 {
        font-size: 15px;
        font-weight: 600;
        margin-bottom: .75rem;
      }
      ol {
        list-style: decimal;
        margin-left: 1.25rem;
        font-size: 13px;
        li {
          margin-bottom: .5rem;
        }
      }
    }
  }
</style>
