<template>
  <div class="timeline">
    <div class="year" v-for="(months, year, index) in chapters" :class="{'active': year === currentYear}" :key="index">
      <div class="year-label"
           :class="{'active': year === currentYear, 'deleted': yearStatus(year)}"
           @click="toggleYear(year)">
        {{year}}
      </div>
      <ul class="months" :class="{'active': year === currentYear}">
        <li v-for="month in months" :key="month.month" v-show="!month.deleted"
            :class="{'active': month.time === currentTime, 'deleted': month.deleted}">
          <a href="javascript:;" @click="changeChapterMonth(month)">
            {{month.month}}
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { findIndex } from 'lodash'
// 时间选择组件
export default {
  name: 'content-timeline',
  props: {
    chapters: {
      type: Object,
      default: function () {
        return {
        }
      }
    },
    activeYear: {
      type: String,
      default: ''
    },
    activeMonth: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      currentYear: '',
      currentTime: ''
    }
  },
  created () {
    this.currentYear = this.activeYear
    this.currentTime = this.activeYear + this.activeMonth
  },
  methods: {
    yearStatus (year) { // whole year months deleted status
      let status = true
      this.chapters[year].map(item => {
        status = status && item.deleted
      })
      return status
    },
    toggleYear (year) {
      let currentYear = year
      if (this.currentYear === year) {
        currentYear = ''
      }
      this.currentYear = currentYear
    },
    // 更改月份
    changeChapterMonth (month) {
      this.currentTime = month.time
      this.$emit('changeMonth', month)
    },
    saveChapters (time) {
      const year = time.substr(0, 4)
      const chapter = findIndex(this.chapters[year], { time })
      this.chapters[year][chapter].deleted = !this.chapters[year][chapter].deleted
    }
  }
}
</script>

<style lang="scss">

.timeline {
  display: inline-block;
  $darkGray: #e9e9e9;
  .year {
    overflow: hidden;
    padding-bottom: .5rem;
    position: relative;
    &.active:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      width: 2px;
      height: 100%;
      background-color: $darkGray;
    }
  }

  .year-label {
    position: relative;
    z-index: 3;
    padding: .6rem 1rem;
    background: $darkGray;
    cursor: pointer;
    border-radius: 4px 0 0 4px;
    font-size: 14px;
    font-weight: 600;
    transition: .35s;
    &.deleted {
      background-color: #e0e0e0;
      text-decoration: line-through;
      &:hover {
        background: lighten($el-color-primary, 20%);
      }
    }
    &:hover {
      background: lighten($el-color-primary, 10%);
      color: white;
    }
    &.active {
      background: $el-color-primary;
      color: white;
    }
  }
  .months {
    overflow: hidden;
    max-height: 0;
    transition: .5s;
    text-align: right;
    &.active {
      max-height: 27em;
    }
    li {
      position: relative;
      z-index: 2;
      transition: .25s;
      border-right: 2px solid $darkGray;
      &.active {
        color: $el-color-primary;
        border-right: 2px solid $el-color-primary;
        a {
          color: $el-color-primary;
        }
      }
      &.deleted a{
        text-decoration: line-through;
      }

      a {
        line-height: 1.56rem;
        height: 1.56rem;
        display: block;
        padding-right: 14px;
        font-size: 14px;
      }
    }
  }
}
</style>
