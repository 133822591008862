<template>
  <div class="profile">
    <template v-if="$route.name === 'profile'">
      <div class="profile-header clearfix">
        <div class="profile-header-logo">
          <img :src="user.avatar" width="60" alt="">
        </div>
        <div class="nickname">
          <b>{{user.nickname}}</b>
          <div class="text-muted small" v-if="user.instagram && user.instagram.username">
            <img class="icon" style="width: 1.2em;"
                 src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAMAAABF0y+mAAABWVBMVEVyHVFHcEx0Fvp8Fv1yFP1/FfyLCOyfBOmtAeS7AuDHAd3UANjZAs7rD6xyFvzjANTqAMHoA7l2F/yPFfx8GPmfD/zFF/XVWujoZuLyZt3pRt/yGdHyA8j1AKTViPj/9Pr////8g9f9A7r3DZz5vO794Pf+ruj9B6uzFPv7JLv+AJr+BI/+YMP/1vD+AIThG+n9GZn/vd3/YZz+AXP+GoT/r8r+AmT/b5z+HHH+I3f+G2P/ur7+FFD+Klb+fYv/wcr+LUf+LWX+AFn+Nzr+Ohz+RT7+inP+Si7/ztD+TxL/zbv+Wy//9fH+WgL+c1X+ZyX+PUv+Bzv+VXD+cxr/6eL+aQT+eAX+nDj+gwD+gxH+fSL+rYn+jwD+iwL+XhX/3rn/1bH9ZR/+lwP+nwH+xHH8ZC3+qAH+sAD+wAP+x1P+zGb+pl79mQn+uAD9J3T+ygD9O1n8pRL9twuaEkMNAAAAc3RSTlMBAF3G///////////GW9j//9nY/1r///////////7//////8b/////////////////////////////////////////////////////////////////////////////////xf///1v////////Y/9j/2VzGSus+GgAAAeNJREFUeAFFjEWiU0EQRc/tqu74V9wZ4TBiB6wG3wpzVoQ7zHCXeELypHH+KVchJAESaEs0IUv0Zv+7fwLgSAOk9ZFghcnfDw4AAg18cyhWCKNVob9NCZjQLWVrEGhJbJGDoCBpMwCrJTETAKBWgdeRGb4dmJW56PdI/KU1K4X15N+AFsW0+3/WVm7Pa0Pmu4Fh7HcbRIjMIhr3FItUGAH4SNZKjK32YjKpK3Iv0yUY0b8g0Vs0oK+8wbeyMBAQwVeo44R1z7XWq6rqpn5jiTmAeQO3iceg9zkYzKvc71iEmMFbqCJJ4tDcKFrLjX5D/MEdq8GpIUUgd/tgGboTgiUDPDZ44SXNpr0gWRcAgqEmEPJRNVPOxYaOth0oAHdCxWoOzslH+RCvlafYMgFUHvAIHoCjz16io80BrH/j0AAcJ9KWKkhn5sRyGjsDtr/sqyrsWNO1cW9/cDNLZgHLSzVvn+wMq9qbKKwy7TiIaKTW56pzW6Oa+N1OKEQdvvl2RyOE4DEOZurd1cHNeab27tyjOPfhFlucXP04ovF97B6jTOzdJwFLaNAff7N6c8incH1VpiSR67q2VgjzUU7JgbMTiQtFKmgAsCAuczlflmVZn7iGBFc1awB/p4tqUS3LDb8OPwBrSrZIO/KHZwAAAABJRU5ErkJggg=="
                 alt="">
            <a :href="'https://instagram.com/' + user.instagram.username" target="_blank">
              @{{user.instagram.username}}
            </a>
          </div>
        </div>
        <button class="text-primary logout-btn" @click="logoutConfirm">{{$tc('profile.itemLogout', 1)}}</button>
      </div>
      <div class="profile-main panel panel-default">
        <div class="panel-heading">
          {{$t('profile.title')}}
        </div>
        <div class="list-group">
          <router-link :to="{name: 'shelf'}" class="list-group-item clearfix">
            <img class="icon" src="../../../static/images/profile/shelf.svg" alt="">
            {{$t('profile.itemShelf')}}
            <i class="ion-chevron-right fr text-gray"></i>
          </router-link>
          <router-link :to="{name: 'order-list'}" class="list-group-item clearfix">
            <img class="icon" src="../../../static/images/profile/orders.svg" alt="">
            {{$t('profile.itemOrder')}}
            <i class="ion-chevron-right fr text-gray"></i>
          </router-link>
          <router-link :to="{name: user.isAgent ? 'agent-info' : 'agent'}" class="list-group-item clearfix">
            <i class="fa fa-child icon"></i>
            我是合夥人
            <i class="ion-chevron-right fr text-gray"></i>
          </router-link>
          <router-link :to="{name: 'profile-invitation'}" class="list-group-item clearfix">
            <img class="icon" src="../../../static/images/profile/invitation.svg" alt="">
            {{$t('profile.itemInvitation')}}
            <span class="icon tag">Hot</span>
            <i class="ion-chevron-right fr text-gray"></i>
          </router-link>
          <router-link :to="{name: 'profile-coupons'}" class="list-group-item clearfix">
            <img class="icon" src="../../../static/images/profile/coupons.svg" alt="">
            {{$t('profile.itemCoupons')}}
            <span class="icon tag">Now</span>
            <i class="ion-chevron-right fr text-gray"></i>
          </router-link>
          <router-link :to="{name: 'group-start'}" class="list-group-item clearfix" v-if="language !== 'en'">
            <img class="icon" style="width: 15px;" src="../../../static/images/profile/purchase.svg" alt="">
            {{$t('profile.itemGroup')}}
            <i class="ion-chevron-right fr text-gray"></i>
          </router-link>
        </div>
      </div>
    </template>
    <router-view></router-view>
  </div>
</template>

<script>
import api from 'src/api/'
import { mapActions, mapGetters } from 'vuex'
import Cookies from 'js-cookie'

export default {
  name: '',
  data () {
    return {}
  },
  computed: {
    ...mapGetters([
      'user', 'language'
    ])
  },
  created () {
  },
  mounted () {
    this.fetchUserInfo()
  },
  beforeRouteLeave (to, from, next) {
    this.setLoading(false)
    next()
  },
  methods: {
    ...mapActions([
      'setLoading', 'setUser'
    ]),
    logoutConfirm () {
      this.$confirm({
        title: this.$t('profile.tipLogout'),
        okText: this.$t('button.cancel'),
        cancelText: this.$t('button.ok'),
        okClass: 'text-gray',
        cancelClass: 'text-red',
        onCancel: modal => {
          this.setUser({})
          Cookies.remove('m_user')
          window.location.href = '/'
          modal.close()
        }
      })
    },
    fetchUserInfo () {
      return api.fetchUser(this.user.uid).then(data => {
        if (data.errors) {
          return
        }
        this.setUser(data)
      })
    }
  }
}
</script>

<style lang="scss">
@import '../../styles/layout/Profile';
</style>
