<template>
  <div></div>
</template>

<script>
import { isEmpty } from 'lodash'
import Cookies from 'js-cookie'
import { mapGetters, mapActions } from 'vuex'
import api from 'src/api'
export default {
  name: '',
  data () {
    return {
      time: 1,
      maxTime: 10,
      timer: null
    }
  },
  computed: {
    ...mapGetters([
      'user'
    ])
  },
  created () {
    this.initLogin()
  },
  methods: {
    ...mapActions([
      'setLoading',
      'setUser'
    ]),
    async initLogin () {
      const { token, uid, state, bid, sid } = this.$route.query
      if (isEmpty(this.$route.query)) { // 当前 login 没有登录参数
        if (!isEmpty(this.user)) {
          this.$router.replace({ name: 'shelf' })
        } else {
          this.setLoading({
            visible: true,
            text: this.$t('login.authorize')
          })
          window.location.href = '/social/facebook_login'
        }
      } else { // 当前可能存在的登录参数
        if (this.user.uid === uid) { // 当前用户已登录，返回书架
          this.handleLoginState(state).then(data => {
            if (bid && sid) {
              this.$router.replace(`/app/design/preview?bid=${bid}&sid=${sid}`)
            } else {
              this.$router.replace('/app/shelf')
            }
          }).catch(e => {
            console.log(e)
          })
          return
        }
        if (token && uid) { // 存在授权信息
          this.setUser({ uid, token: { key: token } })
          await this.fetchUser(uid).then((data) => {
            // 检查用户绑定情况
            this.handleLoginState(state).then(res => {
              const sourceId = Cookies.get('sourceId')
              if (sourceId && sourceId !== 'undefined') {
                this.handleUserBind(sourceId)
              } else {
                Cookies.remove('sourceId')
              }
              // 重定向美国域名
              this.loginRedirect(res.domain, bid, sid) // 处理登录后重定向地址
            }).catch(e => {
              console.log(`handle login state: ${e}`)
            })
          })
        } else { // 当前不存在授权信息
          // this.$router.replace('/')
          window.location.href = '/social/facebook_login' // 暂时直接跳到登录
        }
      }
    },
    fetchUser (uid) {
      return api.fetchUser(uid).then(data => {
        if (!data || data.errors) {
          this.$toast({
            message: this.$t('login.userInfoError'),
            position: 'bottom'
          })
          this.setUser({}) // 清空用戶信息
          this.$router.replace('/')
          return Promise.reject(Error('用户信息错误'))
        }
        this.$toast({
          message: this.$t('login.logged'),
          icon: {
            type: 'ionicon',
            content: 'ion-android-checkmark-circle'
          }
        })
        this.setUser(data)
        return Promise.resolve(data)
      })
    },
    loginRedirect (domain, bid, sid) {
      if (bid && sid) {
        switch (domain) {
          case 'en':
            window.location.href = `https://en.ipastbook.com/app/design/preview?bid=${bid}&sid=${sid}`
            return
          case 'default':
          default:
            this.$router.push(`/app/design/preview?bid=${bid}&sid=${sid}`)
        }
      } else {
        switch (domain) {
          case 'en':
            window.location.href = 'https://en.ipastbook.com/app/shelf'
            return
          case 'default':
          default:
            this.$router.push({ path: '/app/shelf' })
        }
      }
    },
    // 处理登录携带的状态信息 state
    handleLoginState (state) {
      if (typeof state === 'undefined') {
        return Promise.resolve('empty state')
      }
      if (state.includes('redirect')) {
        console.log(`redirect: ${state}`)
        window.location.href = decodeURIComponent(state.split('_')[1])
        return Promise.reject(Error('redirect'))
      }
      state = decodeURIComponent(state)
      const params = state.split('_')
      if (params.length >= 1) {
        let groupLink
        switch (params[0]) {
          case 'cid': // couponId
            window.location.href = `https://ipastbook.com/c/?id=${params[1]}`
            return Promise.reject(Error('redirect to coupon claim'))
          case 'uid': // call refer uid binding
            Cookies.set('sourceId', params[1])
            return Promise.resolve('Got refer')
          case 'group': // group id
            groupLink = '/group/start'
            if (params[1] && params[1] !== String(undefined)) {
              groupLink = `${groupLink}?gid=${params[1]}`
            }
            window.location.href = groupLink
            return Promise.reject(Error('return to group buying'))
          default:
            return Promise.resolve('router:push')
        }
      } else {
        switch (state) {
          case 'test':
            Cookies.set('isBeta', true)
            break
          default:
            // Facebook 广告内参数 ?state=${sourceId}
            if (state && state !== 'None') Cookies.set('sourceId', state)
            Cookies.remove('isBeta')
            break
        }
        return Promise.resolve('router:push')
      }
    },
    // 处理用户上下级关系绑定
    handleUserBind (uid) {
      api.triggerUserBind(uid).then(data => {
        this.time++
        if (!data || data.errors) {
          if (Number(data.errors.code) === 41109) { // 出现未初始化错误进入轮询
            this.timer = setTimeout((uid) => {
              this.handleUserBind(uid)
            }, 800)
            if (this.time > this.maxTime && this.timer) {
              clearTimeout(this.timer)
              this.$toast({
                message: '賬戶初始化失敗，請刷新後重試'
              })
              return
            }
          } else {
            if (this.timer) clearTimeout(this.timer)
            window.ga('send', {
              hitType: 'event',
              eventCategory: 'Error',
              eventAction: JSON.stringify(data),
              eventLabel: 'Network-Error'
            })
            return
          }
        }
        if (this.timer) clearTimeout(this.timer)
        Cookies.remove('sourceId') // 初始化成功之後刪除內容
      }).catch(e => {
        window.ga('send', {
          hitType: 'event',
          eventCategory: 'Error',
          eventAction: JSON.stringify(e),
          eventLabel: 'Catch-Error'
        })
        if (this.timer) clearTimeout(this.timer)
      })
    }
  }
}
</script>

<style lang="scss">

</style>
