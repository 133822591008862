<template>
  <section class="home-words">
    <header>
      <h2 class="section-title">{{title}}</h2>
      <p class="subtitle">
        “這是壹份比任何東西都讓人期待和驚喜的禮物！”
      </p>
    </header>
    <div class="container home-words-content">
      <div class="row back text-center hidden-xs">
        <div class="col-xs-1"></div>
        <div v-for="(item, index) in backItems" :key="index" class="col col-sm-3 col-xs-6">
          <div class="card">
            <div class="card-body">
              <p class="card-text" :title="item.contents">{{item.contents}}</p>
              <div class="nickname text-right">{{item.username}}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="row front is-mobile">
        <div v-for="(item, index) in frontItems" :key="index" class="col col-sm-3 col-xs-6">
          <div class="card">
            <div class="card-body">
              <p class="card-text" :title="item.contents">{{item.contents}}</p>
              <div class="nickname text-right">{{item.username}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import api from 'src/api/'

export default {
  name: '',
  props: {
    hasBg: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: '他們說'
    }
  },
  data () {
    return {
      frontItems: [],
      backItems: [],
      dataLoaded: false
    }
  },
  created () {
    if (process.browser) {
      this.fetchStatements().then(data => {
        this.frontItems = data.splice(-4)
        this.backItems = data.splice(0, 3)
      })
    }
  },
  mounted () {
  },
  methods: {
    fetchStatements () {
      const language = this.$store.state.language.substr(0, 2)
      return api.fetchTestimonials(this.page, language).then(data => {
        data = data.data
        data.map(item => {
          item.image = `${item.images[0]}!320`
          item.avatar = `${item.avatar}!320`
        })
        return data
      })
    }
  }
}
</script>

<style lang="scss">
.home-words {
  padding: 3rem 1rem;

  .swiper-slide {
    box-sizing: border-box;
    padding: 10px 2rem;
  }

  &-content {
    position: relative;

    .front {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
    }

    .back {
      opacity: .75;
      padding-top: 12em;
    }
  }

  .front .card:hover img {
    transform: scale(1.25);
  }

  .card {
    background-color: #fff;
    border-radius: 6px;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, .05);
    min-height: 120px;
    overflow: hidden;
    text-align: left;
    font-size: 14px;

    .card-image {
      position: relative;
      overflow: hidden;

      .img {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background: {
          repeat: no-repeat;
          position: top center;
          size: cover;
        };
      }

      &:after {
        content: "";
        display: block;
        padding-bottom: 67%;
      }
    }

    .card-body {
      overflow: hidden;
      padding: 1rem;
    }

    .card-text {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      max-height: 4.5em;
      overflow: hidden;
      text-overflow: ellipsis;
      height: 70px;
      margin-bottom: 1rem;
    }

    .nickname {
      &:before {
        content: "-";
        display: inline-block;
        margin-right: 4px;
      }
    }
  }

  @media (max-width: 767px) {
    .home-words-content {
      .front {
        position: relative;
        flex-wrap: wrap;
        margin: 0 auto;
      }
    }
  }
}
</style>
