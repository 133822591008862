<template>
  <div class="pages-content">
    <div class="text-center loading" v-if="!dataLoaded">
      <p>
        <i class="fa fa-spinner fa-spin"></i>
      </p>
      <p>{{$t('selection.loadingContentFetching')}}</p>
    </div>
    <div class="row" v-else>
      <div class="col-xs-2">
        <div class="side-timeline">
          <div :class="['time_year', {'active': currentYear === year}]" @click="setActiveYear(year)"
               v-for="year in currentYears" :key="year">{{year}}
          </div>
        </div>
      </div>
      <div class="col-xs-7">
        <template v-if="contents.length">
          <action-item v-for="item in contents"
                       @select="toggleSelect(item)"
                       :info="item" :key="item.scid"/>
        </template>
        <template v-else>
          <p class="text-center">{{$t('selection.tipEmpty')}}</p>
        </template>
      </div>
      <div class="col-xs-2 selection__actions">
        <button class="btn primary" @click="saveLeave" :disabled="saving">
          <i class="fa fa-floppy-o" aria-hidden="true"></i>
          {{$t('pages.save')}}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import api from 'src/api'
import ActionItem from 'src/components/layout/ActionItem.vue'
import { orderBy } from 'lodash'

export default {
  name: 'pages',
  components: { ActionItem },
  data: () => ({
    contents: [],
    currentYear: '',
    currentYears: [],
    timeLine: {},
    selected: [],
    add: [],
    remove: [],
    dataLoaded: false,
    saving: false
  }),
  created () {
    const { pid, sid } = this.$route.query
    if (sid) {
      this.fetchSourceData(sid, pid)
    }
  },
  beforeRouteLeave (to, from, next) {
    if (!this.saving) {
      this.saveContent()
    }
    next()
  },
  methods: {
    async fetchSourceData (sid, pid) {
      if (!sid || !pid) {
        console.warn('fetchPages: Missing required parameters')
        this.dataLoaded = true
        return
      }

      try {
        const data = await api.fetchSocialInfo(sid, pid)

        if (!data || data?.message) {
          console.warn('fetchPages: Invalid response:', data.message)
          return
        }

        // 获取并处理页面数据
        const pageKey = pid.replace('_', '')
        const pageData = data[pageKey]

        if (!pageData) {
          console.warn(`fetchPages: No data found for page: ${pid}`)
          return
        }

        // 解构并更新状态
        const { timeLine, years, activeYear } = this.handleContentData(pageData)
        this.timeLine = timeLine
        this.contents = timeLine[activeYear] || []
        this.currentYears = years
        this.currentYear = activeYear
      } catch (error) {
        console.error('fetchPages error:', error)
      } finally {
        this.dataLoaded = true
      }
    },
    handleContentData (data) {
      const timeLine = {}
      let years = []
      const checked = data.checked.map(item => {
        item.checked = true
        if (!item.selected) {
          const itemId = item.month
          this.workBox.push({
            itemId,
            selected: false
          }) // 进入删除列表
        }
        return item
      })
      const unchecked = data.unchecked.map(item => {
        item.checked = false
        return item
      })
      const contents = checked.concat(unchecked)
      data = orderBy(checked.concat(unchecked), ['month'], ['asc'])
      data.forEach(item => {
        const year = item.month.substr(0, 4)
        const month = item.month.substr(4, 2)
        timeLine[year] = timeLine[year] || []
        timeLine[year].push(Object.assign(
          item,
          {
            month,
            time: item.month
          }
        ))
      })
      years = Object.keys(timeLine).reverse()
      const activeYear = years[0]
      return {
        contents,
        years,
        activeYear,
        timeLine
      }
    },
    setActiveYear (year) {
      this.currentYear = year
      this.contents = this.timeLine[year] || []
    },
    toggleSelect (item) {
      if (!item.checked && !item.selected) {
        this.$toast({ message: '請先恢復該月份內容' })
        return
      }
      item.checked = !item.checked
      if (item.checked) {
        if (!this.add.includes(item.time)) {
          this.add.push(item.time)
        }
        this.remove = this.remove.filter(i => i === item.time)
      } else {
        if (!this.remove.includes(item.time)) {
          this.remove.push(item.time)
        }
        this.add = this.add.filter(i => i === item.time)
      }
    },
    async saveContent () {
      try {
        if (!this.add.length && !this.remove.length) {
          return
        }

        const pid = this.$route.query.pid
        const data = {
          [pid]: {
            add: this.add,
            remove: this.remove
          }
        }

        await api.updateSocialBook(this.$route.query.sid, data)
        this.$toast({
          message: '内容更新成功',
          icon: 'fa fa-check-circle'
        })
        this.saving = true
      } finally {
        this.saving = false
      }
    },
    async saveLeave () {
      await this.saveContent()
      const query = Object.assign({}, this.$route.query)
      delete query.pages
      this.$router.push({
        name: 'selection',
        query
      })
    }
  }
}
</script>

<style lang="scss">
.pages-content {
  margin-top: 4rem;

  .loading {
    i {
      font-size: 2rem;
    }

    p {
      margin: 1rem auto;
    }
  }

  .sidebar__timeline {
    overflow: auto;
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-end;
  }

  .time_year {
    height: 54px;
    line-height: 54px;
    width: 100%;
    max-width: 100px;
    text-align: center;
    color: #999;
    font-weight: 600;
    background-color: #f9f9f9;
    cursor: pointer;

    &.active {
      border-left: 4px solid $el-color-primary;
      background-color: #fff;
    }
  }

  .selection__actions {
    .btn {
      min-width: 120px;
    }

    .btn + .btn {
      margin-top: 1rem;
    }
  }
}
</style>
