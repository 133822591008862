<template>
  <div class="group-event">
    <div class="main">
      <template v-if="!isEventValid">
        <div class="status-content" >
          <template v-if="!dataLoaded">
            <i class="fa fa-spinner fa-spin"></i>
            <p class="mt-1">活動加載中...</p>
          </template>
          <template v-else>
            <i class="fa fa-exclamation-circle"></i>
            <p class="mt-1">活動還沒有開始，請關注粉絲頁，了解更多資訊</p>
          </template>
        </div>
      </template>
      <template v-else>
        <img src="../../../static/images/album-bg.png" alt="" draggable="false">
        <section>
          <h3>邀請{{eventConfig.count}}位好友，免費領取{{bookType}}</h3>
          <div class="group-event__names">
            <template v-for="(item, index) in friends">
              <div class="member" :key="index" @click="startInvite">
                <div class="member-avatar">
                  <img :src="item.avatar" alt="" v-if="item.avatar">
                </div>
                <p>{{item.username || '邀請好友'}}</p>
              </div >
            </template>
          </div>
          <div class="group-event__banner">
            <div class="coupon" :class="{active: currentRefers.length >= referList.length}">
              <div class="coupon-title">{{couponTip}}</div>
              <div class="line"></div>
              <div class="coupon-info">
                <template v-if="referLoaded">
                  <template v-if="currentRefers.length >= referList.length">
                    <p>可用<b>1</b>次</p>
                  </template>
                  <template v-else>
                    <p v-if="referCount.count">已邀请{{referCount.count}}位好友</p>
                    <p>再邀请 <b>{{referCount.want}}</b> 位好友</p>
                  </template>
                </template>
                <template v-if="dataLoaded">
                  <p>僅限 <b>{{bookType}}</b> 產品購買使用</p>
                </template>
              </div>
            </div>
            <button v-if="isEventValid && (currentRefers.length >= referList.length)"
                    class="button mt-1" @click="checkShelf">
              立即使用
            </button>
          </div>
        </section>
        <section>
          <h3>簡單3步獲得{{couponTip}}</h3>
          <Step :title-show="false" :reward="couponTip" :show-paid="false" />
        </section>
        <button class="button" @click="startInvite">邀請好友</button>
      </template>
    </div>
  </div>
</template>

<script>
import InvitationStep from '../../components/layout/InvitationStep'
import FBShare from '../../mixins/FBShare'
import api from 'src/api'
export default {
  name: 'GroupEvent',
  mixins: [FBShare],
  components: {
    Step: InvitationStep
  },
  data: () => ({
    eventConfig: {},
    dataLoaded: false,
    isEventValid: false,
    referList: [],
    currentRefers: [],
    referLoaded: false
  }),
  computed: {
    friends () {
      if (!this.dataLoaded || !this.referLoaded) return []
      if (this.isEventValid) {
        const currentLen = this.currentRefers.length
        const { count } = this.eventConfig
        if (!currentLen) {
          return this.referList
        }
        if (currentLen >= count) {
          return this.currentRefers.slice(0, count)
        }
        const restRefer = Array.from({ length: count - currentLen }, () => ({ username: '' }))
        return this.currentRefers.concat(restRefer)
      }
      return []
    },
    referCount () {
      if (!this.dataLoaded || !this.referLoaded) return {}
      if (this.isEventValid) {
        const currentLen = this.currentRefers.length
        const { count } = this.eventConfig
        return { count: currentLen, want: count - currentLen }
      }
      return {}
    },
    couponTip () {
      if (!this.isEventValid) return ''
      const { couponConfig } = this.eventConfig
      if (couponConfig.type === 'discount') {
        if (!couponConfig.value) {
          return '免單券'
        }
        return `${(10 - couponConfig.value) * 10}% Off`
      }
      return couponConfig.value
    },
    bookType () {
      if (!this.isEventValid) return ''
      const { couponConfig } = this.eventConfig
      if (couponConfig.bookType === 'photobook') {
        const tip = couponConfig.rules && couponConfig.rules.templates ? (couponConfig.rules.templates[0] === 'album' ? '雜誌冊' : '海量冊') : '所有'
        return `相片書${tip}`
      } else {
        const typeset = couponConfig.rules && couponConfig.rules.typeset ? `${couponConfig.rules}排版` : ''
        return `時光書${typeset}`
      }
    }
  },
  mounted () {
    this.fetchEventConfig()
  },
  methods: {
    fetchEventConfig () {
      return api.fetchEventConfig().then(res => {
        this.dataLoaded = true
        if (res.errors) return
        const { id, beginAt, endAt, couponConfig, count } = res
        const isNotStart = (new Date(beginAt)).getTime() > Date.now()
        const isExpires = (new Date(endAt)).getTime() < Date.now()
        if (!couponConfig || isNotStart || isExpires) {
          return
        }
        this.isEventValid = true
        this.eventConfig = res
        this.referList = Array.from({ length: count }, () => ({ username: '' }))
        this.fetchEventDetail(id)
      })
    },
    fetchEventDetail (id) {
      return api.fetchEventDetail(id).then(res => {
        if (res.errors) {
          return
        }
        const { children } = res
        this.currentRefers = children
        this.referLoaded = true
      })
    },
    startInvite () {
      this.FBInvite(this.$store.state.user.uid)
    },
    checkShelf () {
      const { couponConfig } = this.eventConfig
      const { bookType, rules } = couponConfig
      this.$router.push({
        path: '/app/shelf',
        query: {
          tab: bookType,
          template: rules.templates[0]
        }
      })
    }
  }
}
</script>

<style lang="scss">
  .group-event {
    height: 100%;
    padding: 3rem 1rem;
    background-color: #A4282E;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    .main {
      max-width: 480px;
      margin: 4rem auto;
    }
    .status-content {
      color: #fff;
      text-align: center;
      i.fa {
        font-size: 24px;
      }
    }
    section {
      text-align: center;
      background-color: #fff;
      border-radius: 10px;
      margin-bottom: 30px;
      h3 {
        display: inline-block;
        padding: 4px 16px;
        margin: 0;
        font-size: 15px;
        color: #BC4343;
        background: linear-gradient(to bottom, #F6E0B2, #EECC8C);
        border-radius: 16px;
        transform: translateY(-50%);
      }

      .invitation-step {
        margin: 0;
      }
    }
    &__names {
      display: flex;
      padding-bottom: 10px;
    }
    .member {
      flex: 1;
      overflow: hidden;
      color: #BD5558;
      &-avatar {
        position: relative;
        display: inline-block;
        height: 44px;
        width: 44px;
        border-radius: 100px;
        overflow: hidden;
        background-color: #eee;
        cursor: pointer;
        &:empty:before {
          position: absolute;
          content: '';
          display: block;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          background: {
            repeat: no-repeat;
            position: center;
            size: 20px;
            image: url(../../../static/images/red-plus.png);
          };
        }
        img {
          position: relative;
          z-index: 2;
          object-fit: cover;
        }
      }
    }
    &__banner  {
      display: inline-block;
      width: 100%;
      padding: 0 20px;
      margin-bottom: 20px;
      color: #fff;
    }
    .coupon {
      display: flex;
      align-items: center;
      height: 70px;
      border-radius: 6px;
      overflow: hidden;
      background-color: #b5b5b5;
      &.active {
        background-color: #FF545B;
      }
      &-title {
        font-size: 20px;
        padding: 0 10px;
      }
      .line {
        $size: 14px;
        position: relative;
        height: 100%;
        width: 14px;
        &:before, &:after {
          position: absolute;
          content: '';
          display: inline-block;
          height: $size;
          width: $size;
          border-radius: 100px;
          background-color: #fff;
          left: 0;
        }
        &:before {
          top: -$size/2;
        }
        &:after {
          bottom: -$size/2;
        }
      }
      &-info {
        padding-left: 20px;
        flex: 1;
        text-align: left;
      }
    }
    .button {
      background-color: #EDC884;
      border-color:#EDC884;
      color: #B71F36;
      width: 100%;
      height: 48px;
      font-size: 14px;
    }
  }
</style>
