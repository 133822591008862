<template>
  <div class="typeset-type">
    <div>
      <label>A4 版式 （210 × 285mm）</label>
      <div class="typeset-type-list">
        <div class="typeset-type-item A4" v-for="(template, index) in A4" :key="index" @click="$emit('change', template.name)">
          <cover-holder :src="template.cover" :height="coverHeight" :selected="typeset === template.name" />
          <p>
            {{$t(template.label)}}
            <span class="label label-success" v-show="template.new">new</span>
          </p>
        </div>
      </div>
    </div>
    <div>
      <label>A5 版式（148 × 210mm）</label>
      <div class="typeset-type-list">
        <div class="typeset-type-item" v-for="(template, index) in A5" :key="index" @click="$emit('change', template.name)">
          <cover-holder :src="template.cover" :height="coverHeight" :selected="typeset === template.name" />
          <p>
            {{$t(template.label)}}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CoverHolder from '../../../components/CoverHolder'
export default {
  name: 'typeset-type',
  components: { CoverHolder },
  props: {
    typeset: {
      type: String,
      default: 'a5-1'
    }
  },
  data () {
    return {
      coverHeight: 141.1
    }
  },
  computed: {
    A4 () {
      return [
        {
          name: 'a4-1',
          cover: 'https://static.ipastbook.com/assets/images/A4-1.jpg',
          label: 'book.typesetWaterfallA4',
          desc: '照片定宽，排版紧凑'
        },
        {
          name: 'a4-2',
          cover: 'https://static.weixinshu.com/assets/img/A4-1-child.a2effa4c.jpg',
          label: 'A4 童趣',
          desc: '照片定宽，排版紧凑',
          new: true
        }
      ]
    },
    A5 () {
      return [
        {
          name: 'a5-1',
          cover: 'https://static.ipastbook.com/assets/images/A5-1.jpg',
          label: 'cover.labelTemplateWaterfall',
          desc: '图文环绕排版有呼吸'
        },
        {
          name: 'a5-2',
          cover: 'https://static.ipastbook.com/assets/images/A5-2.jpg',
          label: 'cover.labelTemplateColumn',
          desc: '照片定宽，排版紧凑'
        }
      ]
    }
  },
  created () {}
}
</script>

<style lang="scss">
.typeset-type {
  &-list {
    display: flex;
  }
  &>div {
    padding: 10px 0;
  }
  &> div + div {
    margin-top: 10px;
    border-top: 1px solid #eee;
  }
  &-item {
    min-width: 6em;
    width: 25%;
    flex: 0 0 25%;
    position: relative;
    padding: 5px;
    overflow: hidden;
    p {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: .5em;
      font-size: 13px;
      font-weight: 500;
      .label {
        margin-left: 4px;
      }
    }
    &.A4 {
      width: 40%;
      flex: 0 0 40%;
    }
  }
}
</style>
