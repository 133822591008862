<template>
  <div class="order-quantity">
    <label>{{$t('checkout.labelQuantity')}}</label>
    <p>
      <count :number="number" @change="changeNum"></count>
    </p>
  </div>
</template>

<script>
import Count from '../Checkout/Number.vue'
export default {
  name: 'order-quantity',
  components: { Count },
  props: {
    number: {
      type: Number,
      default: 1
    }
  },
  data: () => ({
  }),
  computed: {},
  created () {},
  methods: {
    changeNum (number) {
      this.$emit('change', number)
    }
  }
}
</script>

<style lang="scss">

</style>
