<template>
  <div class="order-pay">
    <label>選擇地址</label>
    <div class="form-group">
      <cell title="收件地址" @click="$router.push({name: 'order-address'})" value="請選擇" :isLink="true">
      </cell>
      <cell title="配送快遞" value="順豐速遞（默認）">
      </cell>
      <cell>
        <textarea class="cell-textarea" placeholder="添加備註"></textarea>
      </cell>
    </div>
    <label>優惠信息</label>
    <div class="form-group">
      <cell title="活動優惠" value="暫無優惠券" :isLink="true">
      </cell>
      <cell title="優惠券抵扣">
        <span>-0.00</span>
      </cell>
    </div>
    <label>付款方式</label>
    <methods @select="selectMethod" :method="orderInfo.method"></methods>
    <div class="sticky-bottom-bar">
      <div class="bottom-item">
        總計: <span class="text-orange text-mini">（包郵）</span>
      </div>
      <button class="btn-block bottom-item paid-btn">
        確認支付
      </button>
    </div>
  </div>
</template>

<script>
import Methods from './PayMethods.vue'
export default {
  name: 'pay',
  components: { Methods },
  data () {
    return {
      orderInfo: {
        method: 'paypal'
      }
    }
  },
  created () {},
  methods: {
    selectMethod (type) {
      this.orderInfo.method = type
    }
  }
}
</script>

<style lang="scss">

  .order-pay {
    padding-bottom:50px;
    label {
      text-indent: 10px;
    }
    i.icon {
      font-size: 28px;
      color: $el-color-primary;
    }
    .paid-btn {
      flex: none;
      padding: 10px 0;;
      width: 100px;
      background-color: $el-color-primary;
      color: white;
    }
  }
</style>
