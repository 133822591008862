<template>
  <div :class="['waterfall-slot', {'single': info.picList.length === 1}]" target="_blank">
    <a class="waterfall-slot__header" :href="postLink" target="_blank">
      <img class="avatar" v-lazy="info.avatar" alt="用户头像">
      <div class="text">
        <p class="title">{{info.username}}</p>
        <p>{{formatDate}}</p>
      </div>
      <div class="fb-link">
        <a :href="postLink" target="_blank">
          <i class="fa fa-facebook-official" aria-hidden="true"></i>
        </a>
      </div>
    </a>
    <div class="cover">
      <div class="cover-wrapper" v-if="info.picList.length">
        <img :src="info.picList[0].src" alt="图片内容"  @load="load" @error="onError">
      </div>
    </div>
    <div class="desc text-ellipsis" v-html="contentParse(info.message)">
    </div>
  </div>
</template>

<script>
import moment from 'moment'
export default {
  props: {
    info: {
      type: Object,
      default: function () {
        return {
          picList: []
        }
      }
    },
    reflow: {
      type: Function,
      default: function () {}
    }
  },
  computed: {
    formatDate () {
      let date = ''
      if (this.info.taggedTime) {
        date = moment(this.info.taggedTime).format('YYYY/MM/DD')
      }
      return date
    },
    postLink () {
      const ids = this.info.taggedId.split('_')
      return `https://www.facebook.com/${ids[0]}/posts/${ids[1]}/`
    }
  },
  mounted () {
    if (this.info.picList.length) {
      this.$el.style.opacity = 0
    }
  },
  methods: {
    load () {
      this.$el.style.opacity = 1
      this.reflow(this.$el)
    },
    onError () {
      this.$el.style.display = 'none'
    },
    // 编辑文本换行
    contentParse (html) {
      return html.replace(/\n/g, '<br>')
    }
  }
}
</script>

<style lang="scss">

  .waterfall-slot {
    display: block;
    width: 300px;
    font-size: 12px;
    line-height: 1.6;
    overflow: hidden;
    transition: 0.2s;
    position: absolute;
    margin-bottom: 1em;
    border: 1px solid #dddfe2;
    border-radius: 3px;
    &.single {
      .waterfall-slot__header {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        background: linear-gradient(to bottom, rgba(0, 0, 0, .75) 0%, rgba(0, 0, 0, 0) 100%);
        text-shadow: 0 1px 2px rgba(0, 0, 0, .5);
        z-index: 10;
        color: white;
        .avatar {
          border: 2px solid white;
        }
        .text, .title, .fb-link a{
          color: white;
        }
      }
    }
    &__header {
      display: flex;
      padding: 12px;
      border-top: 1px solid #f2f2f2;
      cursor: pointer;
      .avatar {
        width: 40px;
        height: 40px;
        display: inline-block;
        vertical-align: top;
        border:1px solid rgba(0,0,0,.1);
      }
      .text {
        margin-left: 10px;
        flex: 1;
        color: #90949c;
        font-weight: 500;
        .title {
          margin: 0;
          font-size: 15px;
          font-weight: 600;
          line-height: 18px;
          color: #1c1e21;
          letter-spacing: .15px;
        }
      }
      .fb-link {
        font-size: 24px;
        a {
          display: flex;
          justify-items: flex-start;
          vertical-align: top;
          color: $color-text-fb;
        }
      }
    }
    >.cover {
      position: relative;
      width: 100%;
      overflow: hidden;
    }
    .desc {
      margin: 10px;
      font-size: 14px;
      line-height: 1.38;
      font-weight: normal;
      overflow: hidden;
    }
  }
</style>
