<template>
  <div class="review">
    <div class="sticky-top-bar review-topbar">
      <div class="bottom-item">
        <router-link to="/" id="logo" v-if="$route.query.action === 'share'">
          <img src="../../../static/images/logo/iPastBook-logo.svg" height="24" alt="">
        </router-link>
        <router-link :to="{name: 'shelf'}" v-else>
          <button class="btn default">
            {{$t('header.navShelf')}}
          </button>
        </router-link>
      </div>
      <div class="bottom-item">
        <router-link :to="user.uid ? {name: 'shelf'} : {name: 'home'}"
                     v-if="$route.query.action === 'share'">
          <button class="btn primary">
            {{$t('header.navStart')}}
          </button>
        </router-link>
        <router-link :to="{name: 'checkout', params: $route.params}" v-else>
          <button class="btn primary">
            {{$t('common.button.purchase')}}
          </button>
        </router-link>
      </div>
    </div>
    <iframe id="flipbook" :src="previewLink" frameborder="0"></iframe>
    <!--<preview @end="handleLastPage"></preview>-->
  </div>
</template>

<script>
import FBShare from 'src/mixins/FBShare'
import { mapGetters } from 'vuex'
import api from 'src/api'
export default {
  name: 'review',
  mixins: [FBShare],
  data () {
    return {
      dataLoaded: false
    }
  },
  computed: {
    ...mapGetters([
      'user'
    ]),
    previewLink () {
      const { query, params } = this.$route
      const bid = query.bid || params.bid
      const origin = /localhost/.test(window.location.origin) ? 'https://test.ipastbook.com' : window.location.origin
      return `${origin}/viewer/?bid=${bid}`
    }
  },
  methods: {
    handleLastPage () {
      this.$confirm({
        title: this.$t('common.toast.confirm.lastPage'),
        okText: this.$t('common.toast.confirm.reading'),
        cancelText: this.$t('common.toast.confirm.shareBook'),
        onOk: (modal) => {
          modal.close()
        },
        onCancel: (modal) => {
          modal.close()
          this.FBShareBook(this.$route.params.bid)
        },
        okClass: 'text-red',
        cancelClass: 'text-primary'
      })
    },
    fetchBook (bid) {
      return api.fetchBook(bid).then(data => {
        this.dataLoaded = true
        // let token = Base64.Base64.btoa()
      })
    }
  }
}
</script>

<style lang="scss">
  .review {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    background-image: url(../../../static/images/placeholder/retina_wood.png);
    #flipbook {
      margin-top: 56px;
      flex: 1;
      @media (max-width: 767px) {
        margin-top: 2rem;
      }
    }
  }
.review-topbar {
  padding: .5rem 1rem;
  .bottom-item:first-of-type {
    justify-content: flex-start;
  }
  .bottom-item:last-of-type {
    justify-content: flex-end;
  }
}
</style>
