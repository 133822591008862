<template>
  <div class="product-sku">
    <h2>{{info.title}}</h2>
    <p class="subtitle">
      {{info.subtitle}}
    </p>
    <div class="product-sku-container">
      <div class="product-sku-intro">
        <template v-if="$route.query.type !== 'calendar'">
          <h3>
            尺寸(cm)
          </h3>
          <div class="row text-center is-mobile">
            <!-- eslint-disable -->
            <div class="col-xs-6" v-for="(item, index) in info.size">
              <button class="btn btn-block"
                      :class="[activeSize === item.name ? 'default-ghost' : 'plant']" @click="setActiveSize(item)">
                {{item.label}}
              </button>
            </div>
          </div>
        </template>
        <div v-for="section in sections" :key="section.key">
          <h3>{{section.title}}</h3>
          <select-group :choices="section.value" v-model="selected[section.key]" />
        </div>
        <template v-if="price">
          <h3>價格</h3>
          <div class="product-sku-price">
            <template v-if="$route.query.type === 'diarybook'">
              {{symbols}}{{price.inner}} / 頁
              <span v-if="price.cover">(基礎價格：{{symbols}}{{price.cover}})</span>
            </template>
            <template v-else-if="$route.query.type === 'calendar'">
              {{symbols}}{{price}}/本
            </template>
            <template v-else>
              {{symbols}}{{price.cover}}
              <span>(+ 內頁：{{symbols}}{{price.inner}}/ 頁)</span>
            </template>
            <span @click="$emit('check')">
              <a href="javascript:;">查看其他幣種價格</a>
            </span>
          </div>
          <p v-if="$route.query.type !== 'calendar'">若您製作
            <b>{{info.num}}</b>
            頁{{info.title}}，價格是 <b>{{symbols}}{{(price.inner * info.num + price.cover).toFixed(1)}}</b></p>
          <p>
            <i class="fa fa-info-circle"></i>
            此處價格僅供參考，實際價格已下單頁面為準
          </p>
        </template>
        <p v-else class="has-text-grey">
          計算中...
        </p>
      </div>
    </div>
    <div class="product-sku-action">
      <p>
        今日下單，預計 <b>{{expressDate}}</b> 發貨
      </p>
      <button class="btn btn-block primary btn-lg" @click="startDraw">開始製作</button>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { find, transform, forEach, isEmpty } from 'lodash'

import SelectGroup from './Selector'

export default {
  name: '',
  components: { SelectGroup },
  props: {
    prices: Object
  },
  data () {
    return {
      showModal: false,
      activeSize: '',
      expressDate: this.$moment().add(7, 'days').format('M月DD日'),
      selected: {}
    }
  },
  computed: {
    symbols () {
      return this.$parent.$parent.symbols
    },
    info () {
      switch (this.$route.query.type) {
        case 'calendar':
          return {
            title: '2020年月曆',
            subtitle: '13張照片，陪妳走過2020年的366天',
            cate: 'photobook',
            path: '/create',
            num: 20,
            size: [
              {
                name: 'traditional',
                label: 'B5 (25 × 17)',
                sections: [
                  {
                    key: 'binding',
                    title: '装帧',
                    value: [
                      { name: '2020-h-traditional', label: '横款' },
                      { name: '2020-v-traditional', label: '竖款' }
                    ]
                  }
                ]
              }
            ]
          }
        case 'photobook':
          if (this.$route.query.template === 'album') {
            return {
              title: 'A4輕奢雜誌冊',
              subtitle: '',
              cate: 'photobook',
              path: '/create',
              num: 24,
              size: [
                {
                  name: 'album',
                  label: 'B5 (25 × 17)'
                }
              ]
            }
          }
          return {
            title: '橫式相片書',
            subtitle: '',
            cate: 'photobook',
            path: '/create',
            num: 20,
            size: [
              {
                name: 'magazine',
                label: 'B5 (25 × 17)',
                sections: [
                  {
                    key: 'binding',
                    title: '封面材質',
                    value: [
                      { name: 'fabric', label: '布藝' },
                      { name: 'leather', label: '皮質' }
                    ]
                  }
                ]
              }
            ]
          }
        case 'diarybook':
        default:
          return {
            title: '時光書',
            subtitle: '臉書5分鐘自動成書| 2種尺寸 | 5種版式 | 2種裝幀 | 海量封面自定義  | 高清印刷',
            cate: 'diarybook',
            path: '/app/shelf?type=diarybook',
            num: 100,
            size: [
              {
                name: 'a5',
                label: 'A5 (14.8 × 21)',
                sections: [
                  {
                    key: 'typeset',
                    title: '版式',
                    value: [
                      { name: 'waterfall', label: '瀑布流' },
                      { name: 'column', label: '雙欄' }
                    ]
                  },
                  {
                    key: 'binding',
                    title: '裝訂',
                    value: [
                      { name: 'paperback', label: '軟裝' },
                      { name: 'hardback', label: '精裝' }
                    ]
                  }
                ]
              },
              {
                name: 'a4',
                label: 'A4 (21× 28.5)',
                sections: [
                  {
                    key: 'typeset',
                    title: '版式',
                    value: [
                      { name: 'waterfall', label: '瀑布流' },
                      { name: 'child', label: '童趣版' }
                    ]
                  },
                  {
                    key: 'binding',
                    title: '裝訂',
                    value: [
                      { name: 'paperback', label: '軟裝' },
                      { name: 'hardback', label: '精裝' }
                    ]
                  }
                ]
              }
            ]
          }
      }
    },
    sections () {
      if (!this.activeSize) {
        return []
      }
      const target = find(this.info.size, { name: this.activeSize })
      return target.sections
    },
    price () {
      if (isEmpty(this.prices)) {
        return null
      }
      if (this.$route.query.type === 'calendar') {
        return this.prices[this.activeSize]
      }
      const activeBinding = this.selected.binding
      const activeSize = this.activeSize || 'a5'
      console.log(this.prices[activeSize])
      if (activeSize === 'album') {
        return this.prices[activeSize].normal
      }
      const prices = this.prices[activeSize]
      return prices[activeBinding]
    }
  },
  created () {
    this.activeSize = this.info.size[0].name
    this.selected = transform(this.info.size[0].sections, (result, value) => {
      const choices = value.value
      result[value.key] = choices[0].name
    }, {})
  },
  methods: {
    ...mapActions(['setLoginShow']),
    setActiveSize (item) {
      this.activeSize = item.name
      // 当前尺寸书籍
      const target = find(this.info.size, { name: item.name })
      forEach(this.selected, (value, key) => {
        if (!target.sections.filter(t => t.key === value).length) {
          const choices = find(target.sections, { key })
          this.selected[key] = choices.value[0].name
        }
      })
    },
    startDraw () {
      if (this.$route.query.type === 'diarybook') {
        if (this.$store.state.user.uid) {
          this.$router.push('/app/shelf')
        } else {
          this.setLoginShow(true)
        }
        return
      }
      const binding = this.selected.binding || 'fabric'
      this.$router.push(`/setup?type=${this.activeSize}&binding=${binding}`)
    }
  }
}
</script>

<style lang="scss">

.product-sku {
  padding: 0 1rem;
  h2 {
    font-size: 1.5rem;
    font-weight: 600;
    margin: 0;
    margin-bottom: 10px;
  }
  .subtitle {
    font-size: 15px;
    font-weight: 400;
    color: #666;
    margin-bottom: 1rem;
  }
  &-container {
    border: 1px solid #eee;
    border-radius: 4px;
    padding: 0 1rem  1.25rem;
    p {
      color: #666;
      font-size: 13px;
      margin: .5rem 0;
    }
  }
  .selector {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    border: 1px solid #eee;
    border-radius: 4px;
    transition: all .35s;
    padding: 6px 0;
    img {
      width: 30%;
      box-shadow: 0 0 4px 2px rgba(0,0,0,.1);
      border-radius: 4px;
    }
  }
  &-price {
    color: $el-color-primary;
    font-weight: 600;
    font-size: 1.25rem;
    span {
      color: #333;
      font-size: 1rem;
    }
  }
  &-action {
    p {
      margin: .5rem 0;

    }
  }
  &-intro {
    h3 {
      font-size: 15px;
      font-weight: 600;
      margin: .5rem 0;
    }
  }
}
</style>
