<template>
  <div class="checkout container-fluid">
    <div class="row">
      <div class="col-xs-12 col-md-5 col-md-offset-1">
        <div class="book-list">
          <router-link
            :to="bookInfo.isFinalize ? {name: 'review', params: $route.params} : {name: 'preview', params: $route.params}">
            <img src="../../../../static/images/placeholder/375x528.png" class="cover-image" alt="" v-if="!dataLoaded">
            <img class="cover-image" :src="bookInfo.cover" v-else alt="">
          </router-link>
          <div class="book-info">
            <h3 class="text-ellipsis">{{bookInfo.title}}</h3>
            <p>{{bookInfo.author}}</p>
            <template v-if="bookInfo.totalPage">
              <p>{{$t('common.label.startTime')}}: {{bookInfo.startDate}}</p>
              <p>{{$t('common.label.endTime')}}: {{bookInfo.endDate}}</p>
            </template>
            <p>{{$t('common.text.typeset')}}: {{$t(bookInfo.template)}}</p>
            <p>{{$t('common.text.bookPages')}}: {{$t('common.text.page', { page: bookInfo.totalPage })}}</p>
            <router-link
              :to="bookInfo.isFinalize ? {name: 'review', params: $route.params} : {name: 'preview', params: $route.params}">
              <button class="btn default small">
                <i class="ion-ios-eye icon" data-icon="ion"></i>
                {{$tc('common.button.check', 1)}}
              </button>
            </router-link>
          </div>
        </div>
        <div class="form-group text-orange text-small">
          <p>
            <b>{{$t('common.text.notify')}}:</b>
          </p>
          <div>1.{{$t('checkout.tip')}}</div>
          <div v-show="bookInfo.totalPage > 240">2.書籍已經超過240頁，系統會為您自動分冊</div>
        </div>
      </div>
      <div class="col-xs-12 col-md-4 col-md-offset-1 package-info">
        <div class="form-group">
          <label>
            {{$t('checkout.label.binding')}}
          </label>
          <p class="binding">
            <template v-for="(binding, index) in bindings">
              <label v-if="binding.default" :key="index">
                <input type="radio" v-model="orderInfo.binding" name="format" :value="binding.name">
                {{$t(binding.desc)}}
              </label>
            </template>
          </p>
        </div>
        <div class="form-group">
          <label>{{$t('common.text.quantity')}}</label>
          <p>
            <count :number="orderInfo.number" @change="number"></count>
          </p>
        </div>
        <div class="form-group coupons">
          <label>
            {{$t('common.text.coupon')}}
          </label>
          <div class="row">
            <div class="col-xs-6">
              <input type="text" :placeholder="$t('checkout.label.coupons')" :value="activeCoupon">
            </div>
            <div class="col-xs-5 col-xs-offset-1">
              <button class="btn primary" @click="showCoupon = true">
                {{$tc('common.button.check', 0)}}
              </button>
            </div>
          </div>
        </div>
        <div class="form-group price">
          <label>
            {{$t('checkout.label.price')}}
            <a class="text-mini text-orange" v-if="false">
              <i class="ion-ios-help-outline icon"></i>
              {{$t('checkout.label.checkPrice')}}
            </a>
          </label>
          <div>
            <strong class="text-red" v-if="!priceLoaded">{{$t('checkout.label.calculating')}}</strong>
            <template v-else>
              <strong class="text-red" :class="{'text-line': activeCoupon}">
                {{symbol}}{{originCost.toFixed(2)}}
              </strong>
              <template v-if="activeCoupon">
                <strong class="text-red">
                  {{symbol}}{{discount.toFixed(2)}}
                </strong>
                {{$t('common.label.discount')}}
                <strong class="text-orange" v-if="activeValue.type === 'discount'">
                  {{100 - activeValue.value * 100}}%
                </strong>
                <strong class="text-orange" v-else>{{symbol}}{{activeValue.value}}</strong>
              </template>
            </template>
          </div>
        </div>
      </div>
      <popup :show="showCoupon" @close="showCoupon = false">
        <div slot="body">
          <div class="text-center" v-if="!couponList.length">
            {{$t('common.toast.tip.couponEmpty')}}
          </div>
          <coupon v-for="(coupon, index) in couponList" :available="true"
                  :enable="enableCoupon(coupon.value)"
                  :coupon="coupon" :key="index" @use="useCoupon"/>
        </div>
      </popup>
    </div>
  </div>
</template>

<script>
import { find, isEmpty, last, orderBy } from 'lodash'
import api from 'src/api/'
import generateCover from 'src/_utils/generateCover'
import Count from './Number'
import Coupon from 'src/components/layout/Coupon'
import config from 'src/app.config'
import currency from 'src/mixins/currency'
import { mapGetters } from 'vuex'

const orderInfo = {
  number: 1,
  binding: 'hardback',
  coupon: '',
  price: 0
}
export default {
  name: 'checkout',
  components: {
    Count,
    Coupon
  },
  mixins: [currency],
  data () {
    return {
      orderInfo: Object.assign({}, orderInfo),
      bindings: config.bindings,
      showCoupon: false,
      templates: config.templates,
      bookInfo: {
        title: '',
        author: '',
        cover: 'http://via.placeholder.com/375x528',
        design: {},
        startDate: '',
        endDate: '',
        typeset: 'a5-waterfall-tweet',
        totalPage: 0,
        source: 'facebook', // 书籍数据来源
        isFinalize: false, // 是否定稿
        isTypesetting: false // 是否在重排
      },
      activeCoupon: '',
      activeValue: {
        type: 'discount',
        value: 0
      },
      prices: {},
      couponList: [],
      dataLoaded: false,
      priceLoaded: false
    }
  },
  computed: {
    ...mapGetters([
      'locale'
    ]),
    originCost () {
      if (!this.priceLoaded) {
        return 0
      }
      // 计算单品价格
      try {
        const priceMaps = this.prices[this.orderInfo.binding]
        const pageLimit = this.orderInfo.binding === 'blog' ? 20 : 50
        const page = this.bookInfo.totalPage < pageLimit ? pageLimit : this.bookInfo.totalPage
        let cost = (priceMaps.cover - 0) + (priceMaps.inner - 0) * page
        cost = cost * this.orderInfo.number
        return cost
      } catch (e) {
        console.error(e)
        return 0
      }
    },
    discount () {
      try {
        if (this.activeValue.type === 'discount') {
          return this.originCost * this.activeValue.value
        } else {
          return this.originCost - this.activeValue.value
        }
      } catch (e) {
        console.error(e)
        return 0
      }
    }
  },
  watch: {
    'orderInfo.binding': function (val) {
      let {
        query,
        params
      } = this.$route
      query = Object.assign({}, query, { binding: val })
      this.$router.push({
        name: 'checkout',
        query,
        params
      })
    },
    'orderInfo.number': function (val) {
      let {
        query,
        params
      } = this.$route
      query = Object.assign({}, query, { quantity: val })
      if (!isEmpty(this.activeCoupon) && !this.activeValue.value && val > 1) {
        query = Object.assign(
          {},
          query,
          {
            quantity: val,
            couponNo: ''
          }
        )
        this.activeCoupon = ''
        this.$toast({
          message: '免單券僅限購買一本'
        })
      }
      this.$router.push({
        name: 'checkout',
        query,
        params
      })
    }
  },
  created () {
    // FB 添加统计
    window.fbq('track', 'Lead')

    const {
      binding,
      quantity,
      couponNo
    } = this.$route.query
    if (binding && find(this.bindings, { name: binding })) {
      this.orderInfo.binding = binding
    }
    if (quantity) {
      this.orderInfo.number = quantity
    }
    this.triggerTypeset() // 初始化前触发排版
    this.fetchBookInfo().then(data => {
      if (!data) {
        return
      }
      this.fetchPrices()
      this.fetchCoupons()
      if (couponNo) {
        this.checkCoupon(couponNo)
      }
    })
  },
  beforeRouteLeave (to, from, next) {
    if (!this.bookInfo.totalPage) { // 书籍页数为 0 禁止继续下单
      if (to.name === 'address' || to.name === 'trade') {
        this.$toast({
          message: this.$t('checkout.toast.contentUncompleted'),
          position: 'bottom'
        })
        return
      }
    }
    if (to.name === 'trade') {
      this.$toast({
        message: this.$t('checkout.toast.addressCompleted'),
        position: 'bottom'
      })
      next({
        name: 'address',
        params: from.params,
        query: from.query
      })
    }
    next()
  },
  methods: {
    triggerTypeset () {
      const { bid } = this.$route.params
      api.triggerTypeset(bid)
      if (!bid) {
        return this.$router.replace({ name: 'not-found' })
      }
    },
    fetchBookInfo () { // 处理书籍数据
      const { bid } = this.$route.params
      if (!bid) {
        return this.$router.replace({ name: 'not-found' })
      }
      return api.fetchBook(bid).then(res => {
        if (!res) {
          this.$router.push({ name: 'not-found' })
          return Promise.resolve(false)
        }

        try {
          const months = orderBy(res.currentChapters, ['month'], ['asc'])
          if (months.length) {
            res.startDate = months[0].month.substr(0, 4) + '/' + months[0].month.substr(4, 2)
            res.endDate = last(months).month.substr(0, 4) + '/' + last(months).month.substr(4, 2)
          } else {
            res.startDate = ''
            res.endDate = ''
          }

          res.retypesetMonths = res.retypesetMonths[res.typeset] ? res.retypesetMonths[res.typeset] : []
          if (res.retypesetMonths.length) {
            res.isTypesetting = true
          }

          if (res.source === 'blog') {
            this.orderInfo.binding = 'blog'
            this.bindings.map((item) => {
              item.default = !item.default
            })
          }

          if (res.isTypesetting) {
            this.$toast({
              message: this.$t('checkout.toast.bookTypesetting')
            })
          }

          res.isFinalize = !res.editable
          res.title = res.design.title
          res.author = res.design.author
          res.cover = generateCover(res.design)
          res.template = find(this.templates, { name: res.typeset }).desc
          delete res.retypesetMonths
          delete res.currentChapters
          delete res.deletedChapters
          this.bookInfo = Object.assign({}, this.bookInfo, res)
        } catch (e) {
          console.error(e)
        }
        this.$route.meta.back = !res.editable ? 'review' : 'preview'
        this.dataLoaded = true
        return Promise.resolve(this.bookInfo)
      }).catch(err => {
        console.log(err)
      })
    },
    fetchPrices () { // 根据服务器价格计算
      const locale = this.locale.toLowerCase()
      api.fetchPrice(locale).then(res => {
        if (!res) {
          return this.$toast({
            message: this.$t('checkout.toast.priceFailed')
          })
        }
        this.handleBackEndPrice(res)
      })
    },
    checkCoupon (couponNo) {
      return api.checkCoupon(couponNo).then(data => {
        if (!data || data.errors) {
          return false
        }
        const coupon = data.coupon
        if (this.dataLoaded) {
          this.useCoupon(coupon)
        }
        return data
      })
    },
    fetchCoupons () {
      api.fetchAllCoupon().then(data => {
        if (!data) {
          this.$toast({
            message: this.$t('checkout.toast.couponFailed')
          })
          return
        }

        const items = []
        data.map(item => {
          if (!isEmpty(item.accountCoupon)) {
            item.coupon.rest = item.accountCoupon.rest
            item.coupon.used = item.accountCoupon.used
          }
          items.push(item.coupon)
        })
        this.couponList = items
      })
    },
    handleBackEndPrice (prices) { // 处理后端脏数据
      const bookPrice = find(prices, { priceType: 'book' })
      if (isEmpty(bookPrice.value)) {
        return this.$toast({
          message: this.$t('checkout.toast.priceConfigEmpty')
        })
      }
      // 获取区间价格
      const a5Price = bookPrice.value.a5
      try {
        Object.keys(a5Price).map((item) => {
          a5Price[item].inner = a5Price[item].inner[200]
        })
      } catch (e) {
        console.error(e)
      }
      this.prices = a5Price
      this.priceLoaded = true
    },
    number (number) {
      this.orderInfo.number = number
    },
    useCoupon (coupon) {
      if ((this.bookInfo.totalPage > 200 || this.orderInfo.number > 1) && !coupon.value) {
        return this.$toast({
          message: '免單券僅限購買一本200頁內的書籍'
        })
      }
      this.activeCoupon = coupon.couponNo
      this.activeValue = {
        type: coupon.type,
        value: coupon.value
      }
      this.showCoupon = false
      let {
        query,
        params
      } = this.$route
      query = Object.assign({}, query, { couponNo: coupon.couponNo })
      this.$router.push({
        name: 'checkout',
        query,
        params
      })
    },
    enableCoupon (coupon) {
      return !(!coupon && (this.bookInfo.totalPage > 200 || this.orderInfo.number > 1))
    }
  }
}
</script>

<style lang="scss">
@import '../../../styles/layout/Checkout.scss';
</style>
