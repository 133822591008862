<template>
  <div class="patch-item">
    <div class="patch-item__header">{{$t('selection.patchTitle')}}</div>
    <div class="patch-item__body">
      <div class="row form-group">
        <div class="col-xs-8">
          <input class="form-control" type="date" v-model="date">
        </div>
        <div class="col-xs-4">
          <input class="form-control" type="time" v-model="time">
        </div>
      </div>
      <textarea class="form-control" :placeholder="$t('selection.patchInputPlaceholder')" v-model="content"></textarea>
      <p class="patch-info">{{$t('image.tipTooSmall')}}</p>
      <div class="patch-item__images">
        <div class="patch-item__image" @click="deleteImage(index)"
             v-for="(image, index) in images" :key="image[0]">
          <div class="uploading-item" :data-orient="image[4]" :style="{backgroundImage: 'url(' + image[1] + ')'}"></div>
          <div class="patch-item__action" role="button">
            <i class="fa fa-times" aria-hidden="true"></i>
          </div>
        </div>
        <div class="patch-item__image"
             v-for="image in tempImages" :key="image.timestamp">
          <div class="uploading-item" :data-orient="image.rotate"
               :style="{backgroundImage: 'url(' + image.image + ')'}"></div>
          <block :done="image.dataLoaded"></block>
        </div>
        <div class="patch-item__image upload-btn">
          <uploader @change="uploadImage" :mixed="true"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from 'src/api'
import EXIF from 'exif-js'
import Block from 'src/components/common/LoadingBlock.vue'

const d = new Date()
const month = `0${d.getMonth() + 1}`.substr(-2)
const day = `0${d.getDate()}`.substr(-2)
const hours = `0${d.getHours()}`.substr(-2)
const minutes = `0${d.getMinutes()}`.substr(-2)
const date = `${d.getFullYear()}-${month}-${day}`
const time = `${hours}:${minutes}`
export default {
  name: '',
  components: { Block },
  data: () => ({
    date,
    time,
    content: '',
    title: '',
    images: [],
    tempImages: []
  }),
  methods: {
    async uploadImage (images) {
      if (this.tempImages.length) {
        this.$toast({ message: '目前尚有上傳內容，請稍後再試' })
        return
      }

      const getOriginUrl = () => /localhost|m\.ipastbook/g.test(window.location.hostname)
        ? 'https://ipastbook.com'
        : window.location.origin

      const formatImageUrl = (imageUrl, origin) =>
        /http?s:/.test(imageUrl) ? imageUrl : `${origin}${imageUrl}`

      const processImages = async (images) => {
        return Promise.all(images.map(async (image) =>
          image.source ? await this.readImageExif(image) : {
            source: image,
            rotate: 0
          }
        ))
      }

      const prepareUploadData = (albums) => {
        return albums.map((item, index) => {
          const timestamp = window.btoa(String(Date.now() + index * 100))
          const form = new FormData()
          form.append('image', item.source)
          return {
            ...item,
            timestamp,
            form
          }
        })
      }

      try {
        const albums = await processImages(images)
        const uploadData = prepareUploadData(albums)
        this.tempImages = uploadData
        const origin = getOriginUrl()

        for (const {
          form,
          timestamp,
          rotate
        } of uploadData) {
          try {
            const data = await api.uploadImage(form)
            const imageUrl = formatImageUrl(data.image, origin)
            const newImage = [data.imageId, imageUrl, false, timestamp, rotate]
            this.tempImages = this.tempImages.filter(img => img.timestamp !== timestamp)
            this.images.push(newImage) // 直接追加到图片数组
          } catch (error) {
            console.error('Upload failed:', error)
            this.tempImages = this.tempImages.filter(img => img.timestamp !== timestamp)
            this.$toast({ message: '图片上传失败' })
          }
        }
      } catch (error) {
        console.error('Upload process failed:', error)
        this.$toast({ message: '图片处理失败' })
        this.tempImages = []
      }
    },
    // 读取图片原信息
    readImageExif (image) {
      return new Promise((resolve, reject) => {
        try {
          EXIF.getData(image.source, function () {
            const allMetaData = EXIF.getAllTags(this)
            image.rotate = allMetaData.Orientation || 0
            resolve(image)
          })
        } catch (e) {
          console.log(e)
          image.rotate = 0
          resolve(image)
        }
      })
    },
    deleteImage (index) {
      this.images.splice(index, 1)
    }
  }
}
</script>

<style lang="scss" scoped>
.patch-item {
  overflow: hidden;

  .patch-item__header {
    font-size: 16px;
  }

  .patch-item__body {
    padding: 1rem 0;

    textarea {
      margin: 1rem 0;
      min-height: 96px;
    }
  }

  .patch-item__images {
    display: flex;
    flex-wrap: wrap;
  }

  .uploading-item {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: {
      size: cover;
      repeat: no-repeat;
      position: 50%;
    }
  }

  .patch-item__image {
    width: 22%;
    padding-bottom: 22%;
    border-radius: 4px;
    position: relative;
    margin-right: 3%;
    margin-bottom: 1rem;
    overflow: hidden;
    background: {
      color: #eee;
      size: cover;
      repeat: no-repeat;
      position: 50%;
    }

    &.upload-btn {
      border: 1px dashed #eee;
      background: {
        color: white;
        size: auto;
        image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUAQMAAAC3R49OAAAABlBMVEUAAADd3d3swgqXAAAAAXRSTlMAQObYZgAAABZJREFUCFtjZPggwIgP/2f4iIIJqAcAuP0X1ZQ/+6oAAAAASUVORK5CYII=);
        repeat: no-repeat;
        position: 50%;
      };

      input {
        pointer-events: none;
      }

      label {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        cursor: pointer;
      }
    }

    p {
      position: absolute;
      color: white;
      top: 50%;
      margin-top: -0.5rem;
      left: 0;
      width: 100%;
      text-align: center;
    }
  }

  .patch-item__action {
    position: absolute;
    top: 0;
    right: 0;
    width: 2.75rem;
    height: 2.75rem;
    line-height: 2rem;
    padding: 0 0 .75rem .75rem;
    background-color: rgba(183, 183, 183, 0.9);
    border-radius: 0 0 0 100px;
    text-align: center;
    color: white;
    font-size: 1.25rem;
  }

  input[type=date], input[type=time] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  .patch-info {
    font-size: 14px;
    margin: 1rem 0;
  }
}
</style>
