<template>
  <div class="content-import">
    <p style="text-align: right;"><span class="fa fa-close close" @click="closeModal"></span></p>
    <div class="text-center">
      <template v-if="step === 'social' && isStep === 'social'">
        <p>您的書裡還沒有內容，請選擇從臉書導入或者直接上傳照片</p>
        <div class="content-source">
          <div v-for="(item, index) in source" :key="index" class="content-source__item-wrapper"
               @click="selectSource(item)">
            <div class="content-source__item">
              <i :class="['source-icon fa', item.icon, item.class]"></i>
              <p>{{item.label}}</p>
            </div>
            <p class="content-source__tip">{{item.tip}}</p>
          </div>
        </div>
      </template>
      <template v-else>
        <p>請選擇您想導入的內容</p>
        <div class="content-source">
          <div :class="['content-source__item', {'active': item.active}]" v-for="(item, index) in types" :key="index"
               @click="selectTypes(item)">
            <i :class="['action fa',  {'fa-check-square': item.active, 'fa-square-o': !item.active}]"/>
            <i :class="['source-icon fa', item.icon]"></i>
            <p>{{item.label}}</p>
          </div>
        </div>
        <button class="btn red" @click="ensureImport">確認並導入</button>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: 'content-import',
  props: {
    isStep: {
      type: String,
      default: 'social'
    }
  },
  data: () => ({
    step: 'social',
    source: [
      { icon: 'fa-facebook-official', class: 'text-fb', label: 'Facebook', value: 'facebook', tip: '一鍵導入臉書內容', active: true },
      { icon: 'fa-instagram', class: 'text-ins', label: 'Instagram', value: 'instagram', tip: '導入Instagram', active: true },
      { icon: 'fa-upload', class: 'text-upload', label: 'Upload', value: 'upload', tip: '上傳照片，寫文字' }
    ],
    types: [
      { icon: 'fa-facebook-official', label: '個人頁（包含相冊）', value: 'posts', active: true },
      { icon: 'fa-font-awesome', label: '粉絲頁', value: 'pages' }
    ]
  }),
  computed: {
    activeTypes () {
      const types = this.types.filter(item => {
        if (item.active) {
          return item
        }
      })
      return types.map(item => item.value)
    }
  },
  methods: {
    closeModal () {
      this.$emit('close')
      this.step = 'social'
    },
    selectTypes (item) {
      this.types = this.types.map(type => {
        if (type.value === item.value) {
          type.active = !type.active
        }
        return type
      })
    },
    selectSource (item) {
      this.source = this.source.map(social => {
        social.active = false
        if (item.value === social.value) {
          social.active = true
        }
        return social
      })
      switch (item.value) {
        case 'facebook':
          this.step = ''
          return
        case 'instagram':
          this.$emit('ins')
          return
        case 'upload':
          this.$emit('patch')
      }
    },
    ensureImport () {
      if (!this.activeTypes.length) {
        this.$toast({
          message: '请确保当前至少选择一种来源'
        })
        return
      }
      this.$emit('select', this.activeTypes)
    }
  }
}
</script>

<style lang="scss">

.content {
  &-import {
    font-size: 15px;
  }
  &-source {
    display: flex;
    justify-content: center;
    margin: 2em 0;
    &__item + &__item, &__item-wrapper + &__item-wrapper {
      margin-left: 1.5em;
    }
    &__tip {
      margin-top: 1.25em;
    }
    &__item {
      position: relative;
      box-shadow: 0 0 5px 2px rgba(0,0,0,.1);
      border-radius: 10px;
      padding: 1.35em 2.5em 1em;
      color: #999;
      cursor: pointer;
      .text-ins {
        color: #A0004A;
      }
      .text-upload {
        color: #68C5B3;
      }
      .action {
        position: absolute;
        top: 6px;
        right: 8px;
        font-size: 1.15em;
      }
      .source-icon {
        font-size: 3.5rem;
      }
      p {
        margin-top: 4px;
        font-size: 1rem;
      }
      &.active {
        background-color: $el-color-primary;
        color: white;
      }
    }
  }
}
</style>
