<template>
  <div class="shelf container">
    <template v-if="promotionLoaded">
      <img class="promotion-image" :src="promotionImages.large" @click="checkPromotion" alt="">
    </template>

    <!--书架加载-->
    <div class="text-center" v-if="fetching">
      <p class="loading-icon"></p>
      <p class="text-gray">{{$t('shelf.tipLoading')}}</p>
    </div>

    <template v-else>
      <div class="text-center create-book-btn">
        <button class="btn primary" :class="[isCreating ? 'disabled' : '']" @click="createBook">
          <i class="fa fa-spinner fa-spin" v-if="isCreating"></i>
          <i class="fa fa-book fa-fw" v-else></i>
          <span v-if="isCreating">{{this.$t('shelf.creating')}}</span>
          <span v-else>{{this.$t('shelf.create')}}</span>
        </button>
      </div>

      <div class="book-list" v-if="currentList.length">
        <book v-for="(book) in currentList" :key="book.bid"
              @delete="deleteAlbumBook"
              @empty="showImport = true"
              :book="book"></book>
      </div>
      <div v-else class="text-center" style="margin-top: 2rem;">
        <img src="../../../static/images/placeholder/pic_empty.png" width="120" alt="">
        <p>您還沒有作品</p>
        <p>趕緊創建一本吧</p>
      </div>
    </template>

    <div class="fb-customerchat"
         :ref="user.uid"
         logged_in_greeting="hi，有做書問題可以私訊我哦！"
         logged_out_greeting="hi，有做書問題可以私訊我哦！"
         page_id="104557935331848">
    </div>
    <!--  选择导入内容  -->
    <modal :show="showImport">
      <content-import slot="body"
                      @ins="startInsAuth"
                      @patch="startPatch"
                      @close="closeImport"
                      @select="selectSource"/>
    </modal>
    <!--  补写书籍内容  -->
    <modal :show="showPatch" class="patch-modal">
      <patch-item ref="patch" slot="body"></patch-item>
      <div class="clearfix" slot="footer">
        <button class="btn plant pull-left" @click="showPatch = false">{{$t('button.cancel')}}</button>
        <button class="btn primary pull-right" @click="savePatchItem">{{$t('button.ok')}}</button>
      </div>
    </modal>
  </div>
</template>

<script>
import { cloneDeep, filter, find, findIndex, last, orderBy } from 'lodash'
import api from 'src/api/'
import generateCover from 'src/_utils/generateCover'
import config from 'src/app.config'
import Book from './Book/Book.vue'
import ContentImport from './ContentImport'
import PatchItem from '../Design/Selection/PatchItem'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'shelf',
  components: {
    Book,
    ContentImport,
    PatchItem
  },
  data () {
    return {
      booksData: [],
      dataLoaded: false,
      showModal: false,
      showDialog: false,
      bookEmpty: false,
      fetching: false,
      showImport: false,
      showPatch: false,
      uploading: false,
      isNavigating: false,
      activeTab: 'diarybook',
      isCreating: false,
      shelfList: {
        diarybook: [], // 日记书数据
        photobook: [], // 相册书数据
        calendar: [] // 月历
      },
      diaryBookEnable: false
    }
  },
  computed: {
    ...mapGetters([
      'user', 'promotionLoaded', 'promotionImages', 'promotionConf'
    ]),
    tabs () {
      return [
        {
          name: '時光書',
          value: 'diarybook'
        },
        {
          name: '相片書',
          value: 'photobook'
        }
      ]
    },
    currentList () {
      return this.shelfList[this.activeTab]
    }
  },
  created () {
    this.fetchBooks()
  },
  methods: {
    ...mapActions([
      'setLoading'
    ]),
    checkPromotion () {
      window.location.href = this.promotionConf.introLink
    },
    deleteAlbumBook (book) {
      if (this.activeTab === 'diarybook' && this.shelfList.diarybook.length <= 1) {
        this.$confirm({
          title: '書架至少保留一本書！',
          cancel: false
        })
        return
      }
      this.$confirm({
        title: '刪除書籍？',
        onOk: (modal) => {
          modal.close()
          const bookDeleteHandler = {
            diarybook: api.deleteDiaryBook,
            photobook: api.deletePhotoBook,
            calendar: api.deleteCalendar
          }
          const tab = this.activeTab || 'diarybook'
          const request = bookDeleteHandler[tab]
          request(book.bid).then(data => {
            if (data.errors) {
              this.$toast({
                message: '刪除失敗'
              })
              return
            }
            const books = cloneDeep(this.shelfList[tab])
            const currentIndex = findIndex(books, { bid: book.bid })
            books.splice(currentIndex, 1)
            this.shelfList[tab] = books
            this.$toast({
              message: '已刪除'
            })
          })
        }
      })
    },
    async createBook () {
      try {
        this.isCreating = true
        const data = await api.createDiaryBook()
        if (data.errors) {
          this.$toast({
            message: this.$t('shelf.exceeded')
          })
          return
        }
        const books = this.handleBooksData([data])
        this.shelfList.diarybook.unshift(...books)
        this.$toast({
          message: this.$t('shelf.created')
        })
      } finally {
        this.isCreating = false
      }
    },
    async fetchBooks () {
      try {
        // 设置加载状态
        this.fetching = true

        // 获取数据
        const data = await api.fetchBooks(this.user.uid)

        // 验证数据有效性
        if (!data || data.errors) {
          const errorCode = data?.errors?.code || ''
          const message = `${this.$t('shelf.tipBookFailed')}${errorCode}`

          this.$toast({
            message,
            position: 'bottom'
          })
          return
        }

        // 解构并更新数据
        const {
          ableToCreateNew,
          books
        } = data
        this.diaryBookEnable = ableToCreateNew
        this.shelfList.diarybook = this.handleBooksData(books)

        // 更新加载状态
        this.dataLoaded = true
      } finally {
        // 重置加载状态
        this.fetching = false
      }
    },
    // 处理书籍数据
    handleBooksData (booksData) {
      booksData.map((item, index) => {
        const months = orderBy(item.currentChapters, ['month'], ['asc'])
        if (months.length) {
          item.startDate = months[0].month.substr(0, 4) + '/' + months[0].month.substr(4, 2)
          item.endDate = last(months).month.substr(0, 4) + '/' + last(months).month.substr(4, 2)
        }
        item.type = 'diarybook'

        try {
          item.retypesetMonths = item.retypesetMonths[item.typeset] ? item.retypesetMonths[item.typeset] : []
          if (item.retypesetMonths.length) {
            item.isTypesetting = true
          }
          item.title = item.design.title
          item.author = item.design.author
          item.coverUrl = generateCover({
            design: item.design,
            chapters: item.currentChapters
          })
          item.template = find(config.templates, { name: item.typeset }).desc
          delete item.deletedChapters
          delete item.currentChapters
        } catch (err) {
          console.error(err)
        }
        return item
      })
      const currentBooks = filter(booksData, { editable: true })
      const finalizeBooks = filter(booksData, { editable: false })
      return currentBooks.concat(finalizeBooks)
    },
    // 删除拉新邀请
    closeImport () {
      this.showImport = false
    },
    selectSource (sources) {
      this.showImport = false
      this.setLoading(true)
      const { bid } = this.currentList[0]
      const { uid } = this.user
      let facebookImportLink = `/social/facebook_data?uid=${uid}&bid=${bid}`
      facebookImportLink = sources.reduce((total, item) => {
        total = `${total}&source=${item}`
        return total
      }, facebookImportLink)
      window.location.href = facebookImportLink
    },
    startInsAuth () {
      const { bid } = this.currentList[0]
      const { uid } = this.user
      window.location.href = `/social/instagram_data?uid=${uid}&bid=${bid}`
    },
    startPatch () {
      this.showImport = false
      this.showPatch = true
    },
    savePatchItem () {
      if (this.uploading) return
      const {
        content,
        title,
        date,
        time,
        images
      } = this.$refs.patch
      let picList = images.slice()
      picList = picList.map(item => {
        return item[0]
      })
      if (!content && !picList.length) {
        this.$toast({
          message: this.$t('selection.tipCompletedPatch')
        })
        return
      }
      const dateArr = date.split('-')
      if (dateArr[0].length !== 4) {
        this.$toast({
          message: this.$t('selection.tipTimeError')
        })
        return
      }
      const item = {
        title,
        content,
        postDate: `${date}T${time}`,
        picList
      }
      this.$toast({
        message: this.$t('selection.tipSaving'),
        duration: 1000,
        position: 'bottom'
      })
      this.showModal = false
      const {
        source,
        bid
      } = this.currentList[0]
      this.uploading = true
      api.createItemContent(source, item).then(data => {
        this.uploading = false
        if (data.errors) return
        this.$toast({
          message: this.$t('selection.tipSaved'),
          duration: 1500,
          icon: 'fa-check-circle'
        })
        this.$router.push({
          name: 'selection',
          query: {
            bid,
            sid: source,
            tab: 'patch'
          }
        })
      }).catch(e => {
        this.uploading = false
        this.$toast({
          message: '保存失败'
        })
      })
    }
  }
}
</script>

<style lang="scss">
.shelf.container {
  padding: 20px;
  position: relative;

  .promotion-image {
    position: absolute;
    right: 20px;
    top: 10px;
    display: block;
    max-width: 240px;
    margin: 0 auto;
    cursor: pointer;
  }

  .action-bar {
    margin-bottom: 1rem;

    button {
      margin-right: .5rem;

      &:hover {
        transform: translateY(-2px);
        box-shadow: $dp-1;
      }
    }
  }

  .patch-modal .modal-content {
    width: 600px;
    max-width: 600px;
  }

  .book-list {
    text-align: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 2rem;
    gap: 20px;
    justify-content: center;

    .book {
      flex-basis: calc((100% - 20px) / 2);
    }

    @media screen and (max-width: 600px) {
      .book {
        flex-basis: 100%;
      }
    }
  }

  .empty-shelf {
    margin-top: 1rem;
  }

  .fade-in-enter-active {
    transition: all 0.5s ease-in-out;
  }

  .fade-in-enter {
    opacity: 0;
    transform: translateX(50px);
  }

  .side-btns {
    position: fixed;
    left: 0;
    bottom: 2em;
    z-index: 10;
    flex-direction: column;

    .btns {
      font-size: 14px;
      display: block;
      width: 2.5em;
      line-height: 1.35;
      white-space: inherit;
      text-align: center;
      padding: .5em;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      transform: translateX(-10%);
      transition: .3s;
      margin-bottom: .5rem;
    }
  }

  .shelf-tabs {
    position: relative;
    width: 100%;
    overflow: hidden;
    margin: 1rem 0;
    text-align: center;

    &__item-wrapper {
      display: inline-block;
    }

    &__item {
      display: inline-block;
      padding: 0 .75em;
      font-weight: 500;
      cursor: pointer;

      &.active {
        color: $el-color-primary;

        &:after {
          content: '';
          display: block;
          height: 2px;
          width: 100%;
          background-color: $el-color-primary;
          margin-top: .5rem;
        }
      }
    }
  }

  .create-book-btn {
    margin-top: 1rem;

    button {
      width: 100%;
      max-width: 240px;
      font-weight: 500;
      font-size: 14px;
      line-height: 2;
      box-shadow: 0 0 3px 2px rgba(245, 99, 104, .25);
    }
  }
}
</style>
