<template>
  <div class="previewer">
    <div v-if="initLoaded === false" style="margin: auto;">
      <div style="display: flex; justify-content: center;">
        <img style="width: 70px;" src="../../../../static/images/turning.gif" alt="">
      </div>
      <div>Loading...</div>
    </div>
    <template v-else>
      <div class="previewer__timeline timeline">
        <transition name="slide-up" mode="out-in">
          <div class="year-label" @click="startRead" v-if="!isStart">{{$t('preview.buttonMenu')}}</div>
        </transition>
        <time-line :chapters="book.timeLine"
                   :activeYear="activeYear"
                   :activeMonth="activeMonth"
                   @changeMonth="movePage"
                   v-if="dataLoaded && isStart">
        </time-line>
      </div>
      <iframe id="turning-book" :src="previewLink" frameborder="0"></iframe>
      <div class="previewer__menu">
        <p>
          <router-link :to="{name: 'order-check', query: $route.query}">
            <button class="btn primary">
              <i class="fa fa-shopping-cart" aria-hidden="true"></i>
              {{$t('preview.buttonPurchase')}}
            </button>
          </router-link>
        </p>
        <template v-if="!$route.query.locked">
          <p>
            <router-link :to="{name: 'selection', query: $route.query}">
              <button class="btn secondary">
                <i class="fa fa-pencil" aria-hidden="true"></i>
                {{$t('preview.buttonEdit')}}
              </button>
            </router-link>
          </p>
          <p>
            <router-link :to="{name: 'cover', query: $route.query}">
              <button class="btn secondary">
                <i class="fa fa-tachometer" aria-hidden="true"></i>
                {{$t('preview.buttonDesign')}}
              </button>
            </router-link>
          </p>
        </template>
      </div>
    </template>
  </div>
</template>

<script>
import api from 'src/api'
import TimeLine from '../Content/Timeline.vue'
import FBShare from 'src/mixins/FBShare'
import resolveBookData from '../resolveBookData'
import { mapGetters } from 'vuex'

export default {
  name: 'preview',
  components: { TimeLine },
  mixins: [FBShare],
  data () {
    return {
      page: 1,
      currentPage: 1,
      channel: null,
      otherWindow: null,
      dataLoaded: false,
      bookLoaded: false,
      book: {},
      activeYear: '',
      activeMonth: '',
      isStart: false,
      initLoaded: false,
      interval: null,
      bid: ''
    }
  },
  computed: {
    ...mapGetters([
      'user'
    ]),
    previewLink () {
      const {
        query,
        params
      } = this.$route
      const bid = query.bid || params.bid
      return `/viewer/?bid=${bid}`
    }
  },
  beforeDestroy () {
    clearInterval(this.interval)
  },
  created () {
    const bid = this.$route.params.bid || this.$route.query.bid
    this.bid = bid
    this.triggerTypeset(bid)
  },
  mounted () {
    this.init()
  },
  methods: {
    async init () {
      try {
        let result
        let i = 0
        while (!result) {
          result = await this.loadBook()
          await new Promise(resolve => setTimeout(resolve, 1000))
          i++
          if (i > 50) {
            return
          }
        }
        this.book = result.book
        this.years = result.years
        if (this.book.timeLine) {
          this.activeYear = Object.keys(this.book.timeLine)[0]
          this.activeMonth = this.book.timeLine[this.activeYear][0].month
        }
      } finally {
        this.$nextTick(() => {
          this.channel = new MessageChannel()
          this.otherWindow = this.$el.querySelector('#turning-book').contentWindow
          this.channel.port1.onmessage = (msg) => {
            const { data } = msg
            if (data.bid) {
              const { totalPage } = data
              this.page = totalPage % 2 === 0 ? totalPage + 4 : data + 5
              const {
                book,
                bookLoaded,
                years
              } = resolveBookData(data)
              this.bookLoaded = bookLoaded
              this.years = years
              this.book = book
            } else {
              this.currentPage = data.currentPage
            }
          }
          this.otherWindow.postMessage('initialize', '*', [this.channel.port2])
        })
      }
    },
    async loadBook () {
      if (this.fetching) {
        return
      }
      try {
        this.fetching = true
        const data = await api.fetchBook(this.bid)
        const retypesetMonth = data.retypesetMonths[data.typeset]
        if (!retypesetMonth?.length) {
          this.initLoaded = true
          this.dataLoaded = true
          return resolveBookData(data)
        }
      } finally {
        this.fetching = false
      }
    },
    triggerTypeset (bid) {
      return api.triggerTypeset(bid)
    },
    movePage (month) {
      if (month.deleted) {
        this.$confirm({
          message: '該月份被隱藏，是否恢復'
        })
        return
      }
      this.otherWindow.postMessage('' + month.pages, '*')
    },
    startRead () {
      if (!this.otherWindow) {
        this.$toast({
          message: '書籍未加載成功'
        })
        return
      }
      this.otherWindow.postMessage('' + 3, '*')
      this.isStart = true
    }
  }
}
</script>

<style lang="scss">
.container.preview-bg {
  width: 1170px;
  @media (max-width: 1279px) {
    width: 100%;
    .previewer__timeline, .previewer__menu {
      transform: translateX(0);
    }
  }
}

.slide-up-enter-active, .slide-up-leave-active {
  transition: transform .5s;
  will-change: transform;
}

.slide-up-leave-to /* .fade-leave-active below version 2.1.8 */
{
  transform: translateY(-100%);
}

.previewer {
  position: relative;
  flex: 1;
  flex-grow: 1;
  width: 100%;
  display: flex;
  margin-top: 2rem;
  @media (max-width: 767px) {
    margin-top: 60px;
  }

  .previewer__timeline, .previewer__menu {
    position: absolute;
    top: 3rem;
    z-index: 5;
  }

  .previewer__timeline {
    left: 0;
    transform: translateX(-100%);

    & > .year-label {
      padding: .6rem 1rem;
      background: #e9e9e9;
      cursor: pointer;
      border-radius: 4px 0 0 4px;
      font-size: 14px;
      font-weight: 600;
      transition: .35s;
      margin-bottom: 1rem;
    }
  }

  .previewer__menu {
    right: 0;
    transform: translateX(100%);
  }

  #turning-book {
    height: 100%;
    flex: 1;
  }
}
</style>
