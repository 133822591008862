<template>
  <div class="address-confirm container-fluid">
    <h4 class="text-center">
      {{$t('address.toast.title')}}
    </h4>
    <div class="row text-small form-group">
      <template v-if="binding === 'pdf'">
        <div class="col-xs-4 padding-0 text-right">
          {{$t('common.label.receiver')}}：
        </div>
        <div class="col-xs-8 padding-0">
          {{address.firstName}} {{address.lastName}}
        </div>
      </template>
      <template v-else>
        <div class="col-xs-4 col-sm-2 padding-0 text-right">
          {{$t('common.label.addressDetail')}}：
        </div>
        <div class="col-xs-8 col-sm-10 padding-0">
          {{address.line1}}, {{address.line2}}, {{address.city}}, {{address.state}}, {{address.postalCode}},
        </div>
      </template>
    </div>
    <div class="row text-small">
      <template v-if="binding === 'pdf'">
        <div class="col-xs-4 padding-0 text-right" >
          {{$t('common.label.email')}}：：
        </div>
        <div class="col-xs-8 padding-0 text-orange">
          <strong>{{address.email}}</strong>
        </div>
      </template>
      <template v-else>
        <div class="col-xs-4 padding-0 text-right" >
          {{$t('common.label.telephone')}}：
        </div>
        <div class="col-xs-8 padding-0 text-orange">
          <strong>{{address.phone}}</strong>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  props: {
    address: {},
    binding: {
      type: String,
      default: 'hardback'
    }
  },
  data () {
    return {}
  },
  created () {}
}
</script>

<style lang="scss">
  .address-confirm {
    h4 {
      font-size: 16px;
      margin-bottom: 1rem;
    }
    .padding-0 {
      padding: 0!important;
    }
  }
</style>
