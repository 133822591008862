import Design from './Design.vue'
const design = [
  {
    path: '/app/design/',
    name: 'design',
    component: Design,
    meta: {
      title: 'title.cover',
      root: 'design'
    },
    children: [
      {
        path: 'cover',
        name: 'cover',
        component: (resolve) => {
          require(['./Cover/Cover.vue'], resolve)
        },
        meta: {
          title: 'title.cover',
          step: 1,
          backName: 'shelf', // 书籍是否定稿，返回不同预览
          nextName: 'content',
          backText: 'common.button.shelf'
        }
      },
      {
        path: 'selection',
        name: 'selection',
        component: (resolve) => {
          require(['./Selection/selection.vue'], resolve)
        },
        meta: {
          title: 'selection.title',
          step: 1,
          backName: 'title.preview', // 书籍是否定稿，返回不同预览
          nextName: 'content',
          backText: 'common.button.shelf'
        }
      },
      {
        path: 'pages',
        name: 'pages',
        component: (resolve) => {
          require(['./Pages/Pages.vue'], resolve)
        },
        meta: {
          title: 'title.selection',
          step: 1,
          backName: 'selection', // 书籍是否定稿，返回不同预览
          nextName: 'content',
          backText: 'common.button.shelf'
        }
      },
      {
        path: 'content',
        name: 'content',
        component: (resolve) => {
          require(['./Content/Content.vue'], resolve)
        },
        meta: {
          title: 'title.cover',
          step: 2,
          backName: 'cover', // 书籍是否定稿，返回不同预览
          nextName: 'preview',
          backText: 'common.button.previous',
          nextText: 'common.button.save'
        }
      },
      {
        path: 'preview',
        name: 'preview',
        component: (resolve) => {
          require(['./Preview/Previewer.vue'], resolve)
        },
        meta: {
          title: 'title.preview',
          step: 3,
          root: 'design',
          backName: 'content', // 书籍是否定稿，返回不同预览
          nextName: 'checkout',
          nextText: 'common.button.purchase'
        }
      }
    ]
  }
]

export default design
