<template>
  <div>
    <cell @click="$emit('select', 'paypal')">
      <label slot="title"></label>
      <img src="../../../../static/images/payment/Paypal.svg" slot="icon" width="100" alt="paypal">
      <i class="ion ion-ios-circle-outline icon" v-if="method !== 'paypal'"></i>
      <i class="ion ion-ios-checkmark icon" v-else></i>
    </cell>
    <cell title="支付寶" @click="$emit('select', 'alipay')">
      <img src="../../../../static/images/payment/alipay.png" slot="icon" width="24" alt="支付寶">
      <i class="ion ion-ios-circle-outline icon" v-if="method !== 'alipay'"></i>
      <i class="ion ion-ios-checkmark icon" v-else></i>
    </cell>
  </div>
</template>

<script>
export default {
  name: 'pay-methods',
  props: {
    method: String
  },
  data () {
    return {}
  },
  created () {}
}
</script>

<style lang="scss">

</style>
