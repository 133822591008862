<template>
  <div class="viewer">
    <div class="viewer__wrapper">
      <div class="swiper-wrapper">
        <div class="swiper-slide" @click="onClose" v-for="(item, index) in $options.images" :key="index">
          <img :data-src="item" class="swiper-lazy">
          <div class="swiper-lazy-preloader"></div>
        </div>
      </div>
      <div class="swiper-pagination"></div>
    </div>
  </div>
</template>

<script>
import Swiper from 'swiper/js/swiper.min.js'
export default {
  name: '',
  props: {
    images: {
      type: Array,
      default: function () {
        return []
      }
    },
    activeIndex: {
      type: Number,
      default: 0
    },
    onClose: {
      type: Function,
      default: function () {
        return function () {
        }
      }
    }
  },
  data: () => ({
  }),
  computed: {
  },
  mounted () {
    this.$nextTick(function () {
      const swiper = new Swiper('.viewer__wrapper', {
        paginationClickable: true,
        initialSlide: this.activeIndex,
        lazy: true,
        pagination: {
          el: '.swiper-pagination',
          type: 'fraction'
        }
      })
      console.log(swiper)
    })
  },
  created () {},
  methods: {}
}
</script>

<style lang="scss">
  .viewer {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #000;
    z-index: 1999;
    .viewer__wrapper {
      display: flex;
      align-items: center;
      height: 100%;
    }
    .swiper-slide {
      display: flex;
      height: 100%;
      justify-content: center;
      align-items: center;
      img {
        cursor: zoom-out;
        max-height: 100%;
      }
    }
    .swiper-pagination {
      color: white;
    }
  }
</style>
