<template>
  <div class="groups-item" :class="[info.coupon.bookType]">
    <div class="groups-item-banner" />
    <div class="groups-item-info">
      <h4 class="flex-space">
        <p v-html="bookType" class="groups-item-info-title"></p>
        <p v-if="!isExpired">倒計時</p>
      </h4>
      <div class="flex-space">
        <strong>{{discount}}</strong>
        <span>
          <template v-if="!isExpired">{{info.coupon.startAt}}成團，剩餘</template>
          {{countDown}}
        </span>
      </div>
      <div class="flex-space">
        <div class="avatars">
          <div class="avatars-image" v-for="(avatar, index) in info.users.avatars" :key="index"
               :style="{backgroundImage: `url(${avatar})`}"></div>
        </div>
        <span>已经有{{info.users.count}}人參與團購</span>
      </div>
      <div class="text-center mt-1">
        <template v-if="info.isFull">
          <button class="btn primary btn-block" disabled>已滿員</button>
        </template>
        <template v-else-if="isExpired">
          <button class="btn btn-secondary btn-block" disabled>已結束</button>
        </template>
        <div class="row" v-else>
          <div class="col-xs-6">
            <button class="btn default btn-block btn-lg" @click="callFacebook" v-if="user.uid">分享給好友</button>
          </div>
          <div :class="[user.uid ? 'col-xs-6' : 'col-xs-12' ]">
            <button class="btn primary btn-block btn-lg" :disabled="info.loading" @click="joinGroup" v-if="!info.fetched">
              <i class="fa fa-spinner fa-spin" v-if="info.loading"></i>
              我要參團
            </button>
            <button class="btn primary btn-block btn-lg" disabled v-else>已領取</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FBShare from 'src/mixins/FBShare'
import { mapGetters } from 'vuex'
export default {
  name: '',
  mixins: [FBShare],
  props: {
    info: {
      type: Object,
      default: function () {
        return {
          coupon: {
            bookType: 'diarybook'
          },
          fetched: false,
          users: {
            count: 0,
            avatars: []
          }
        }
      }
    }
  },
  data: () => ({
    countDown: '',
    isExpired: false
  }),
  computed: {
    ...mapGetters(['user']),
    bookType () {
      switch (this.info.coupon.bookType) {
        case 'diarybook':
        default:
          return '時光書 - <span>印出你的“臉書”</span>'
        case 'photobook':
          return '相片書 - <span>用相片記錄感動</span>'
        case 'calendar':
          return '月曆 - <span>給2020一份期待 </span>'
      }
    },
    discount () {
      const { type, value } = this.info.coupon
      if (type === 'voucher') {
        return value + '頁'
      } else if (type === 'cash') {
        return value
      } else {
        return Math.round(100 - 100 * value) + '% off'
      }
    }
  },
  created () {
    this.timeCounter()
  },
  methods: {
    joinGroup () {
      this.$emit('claim')
    },
    timeCounter () {
      let beginAt = new Date(this.info.coupon.beginAt)
      beginAt = beginAt.getTime()

      const _this = this
      calc()
      function calc () {
        let timer = null
        const timeDiff = Math.floor((beginAt - (Date.now())) / 1000)
        if (timeDiff <= 0) {
          _this.countDown = '已結束'
          _this.isExpired = true
          clearTimeout(timer)
          return
        }
        const day = Math.floor(timeDiff / (24 * 60 * 60))
        let hours = Math.floor((timeDiff - day * 24 * 60 * 60) / 3600)
        let minutes = Math.floor((timeDiff - day * 24 * 60 * 60 - hours * 60 * 60) / 60)
        let seconds = Math.floor(timeDiff - day * 24 * 60 * 60 - hours * 60 * 60 - minutes * 60)
        hours = `0${hours}`.substr(-2)
        minutes = `0${minutes}`.substr(-2)
        seconds = `0${seconds}`.substr(-2)
        _this.countDown = day ? day + '天' + hours + ':' + minutes + ':' + seconds : hours + ':' + minutes + ':' + seconds
        timer = setTimeout(() => {
          calc()
        }, 1000)
      }
    },
    callFacebook () {
      this.FBShareGroups(this.user.uid)
    }
  }
}
</script>

<style lang="scss">

.groups-item {
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 40px 0 rgba(198,198,198,0.5);
  margin-bottom: 1.5rem;
  &.diarybook {
    .groups-item-banner {
      background-image: url(../../../static/images/home/product-diarybook.png);
    }
  }
  &.photobook {
    .groups-item-banner {
      background-image: url(../../../static/images/home/photobook-thumb.jpg);
    }
  }
  &.calendar {
    .groups-item-banner {
      background-image: url(../../../static/images/product/calendar-1.png);
    }
  }
  &-banner {
    width: 100%;
    padding-bottom: 48%;
    background: {
      repeat: no-repeat;
      size: cover;
      position: bottom center;
    }
  }
  &-info {
    padding: 1rem;
    &-title {
      span {
        color: #666;
      }
    }
    h4 {
      font-size: 16px;
      font-weight: 500;
    }
    .flex-space {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 8px;
      strong {
        font-size: 20px;
        color: $el-color-primary;
        font-weight: 600;
      }
      span {
        color: #979797;
      }
    }
    .avatars {
      display: flex;
      &-image {
        height: 2rem;
        width: 2rem;
        border-radius: 100px;
        border: 1px solid #fff;
        overflow: hidden;
        background: {
          color: #eee;
          repeat: no-repeat;
          position: center;
          size: cover;
        };
        margin-left: -0.5rem;
        &:first-child {
          margin-left: 0;
        }
      }
    }
  }
}
</style>
