<template>
  <section class="product-materials">
    <header>
      <h2>{{material.title}}</h2>
      <p class="subtitle">
        {{material.subtitle}}
      </p>
    </header>
    <div class="container has-text-centered">
      <img v-lazy="material.imageOne" class="hidden-xs" alt="" draggable="false">
      <img v-lazy="material.imageOneMobile" class="visible-xs" alt="" draggable="false">
      <img v-lazy="material.imageTwo" class="hidden-xs" alt="" draggable="false">
      <img v-lazy="material.imageTwoMobile" class="visible-xs" alt="" draggable="false">
    </div>
  </section>
</template>

<script>
export default {
  name: '',
  props: {
    material: Object
  },
  data: () => ({
  }),
  computed: {},
  created () {},
  methods: {}
}
</script>

<style lang="scss">
  .product-materials {
    img + img {
      margin-top: 1.5rem;
    }
  }
</style>
