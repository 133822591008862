<template>
  <div class="product-creation">
    <div class="banner">
      <div class="holder"></div>
      <div class="product-creation-previewer" v-if="templateStyle === 'magazine' || templateStyle === 'album'" ref="pages">
        <div class="pages-wrapper" v-if="templateLoaded">
          <div class="page">
            <div class="page-content cover" v-if="templateStyle === 'album'"
                 v-lazy:backgroundImage="`https://canvas.xinshu.me/generate/${designType}`" />
            <div class="page-content cover"
                 v-lazy:backgroundImage="`https://canvas.xinshu.me/generate/cover-magazine-1.${activeType}`" v-else />
          </div>
          <template v-for="(page, index) in templatePages">
            <div v-html="page" class="page" :key="index"  />
          </template>
        </div>
        <div class="mt-1 text-center" v-else>
          <div class="loading-icon" />
          <p class="mt-1">頁面加載中</p>
        </div>
      </div>
      <div class="product-creation-previewer" v-lazy:backgroundImage="banner" v-else />
    </div>
    <div class="title">
      {{title}}
    </div>
    <template v-if="templateStyle !== 'album'">
      <label>
        <template v-if="templateStyle !== 'traditional'">
          選擇封面材質
        </template>
        <template v-else>
          選擇月曆版式
        </template>
      </label>
      <div class="btns">
        <button v-for="(template, index) in templateList" :key="index"
                class="btn" :class="[activeType === template.name ? 'primary' : 'plant']"
                @click="setActiveType(template)">
          {{template.label}}
        </button>
      </div>
      <p class="has-text-grey has-text-weight-semibold">
        <i class="fa fa-info-circle"></i>
        <template v-if="templateStyle !== 'traditional'">
          創建后仍可在封面編輯中切換材質
        </template>
        <template v-else>
          創建后仍可在模板中切換風格
        </template>
      </p>
    </template>
    <template v-if="templateStyle !== 'traditional'">
      <label>選擇內頁風格</label>
      <div class="template-styles">
        <a v-for="(item, index) in templateStyles" :key="index"
           href="javascript:;" target="_self" class="template-styles-item" @click="changeTemplateStyle(item)">
          <div class="template-styles-banner">
            <cover-holder :src="item.banner" :selected="activeStyle === item.name">
              <div class="tag" v-if="item.text">文字</div>
            </cover-holder>
          </div>
          <p class="text-center">{{item.label}}</p>
        </a>
      </div>
      <p class="has-text-grey has-text-weight-semibold">
        <i class="fa fa-info-circle"></i>
        創建后仍可更換風格
      </p>
    </template>
    <button class="action btn btn-block primary btn-lg"
            :class="[isCreating ? 'disabled' : '']"
            @click="createBook">
      <i class="fa fa-spinner fa-spin" v-show="isCreating"></i>
      <span v-if="isCreating">创建中...</span>
      <span v-else>新建{{title}}</span>
    </button>
  </div>
</template>

<script>
import api from 'src/api'
import { mapActions } from 'vuex'
import CoverHolder from '../components/CoverHolder'
import { find } from 'lodash'
export default {
  name: 'product-create',
  components: {
    CoverHolder
  },
  data: () => ({
    templateStyles: [
      {
        name: 'magazine-new',
        label: '簡約',
        banner: require('../../static/images/placeholder/simple.jpg')
      },
      {
        name: 'magazine-1',
        label: '旅行',
        banner: require('../../static/images/placeholder/travel.jpg'),
        text: true
      },
      {
        name: 'magazine-child-psb',
        label: '童趣',
        banner: require('../../static/images/placeholder/child.png'),
        text: true
      },
      {
        name: 'magazine-baby-psb',
        label: 'baby',
        banner: require('../../static/images/placeholder/template-baby.jpg'),
        text: true
      },
      {
        name: 'magazine-japan-psb',
        label: '和风',
        banner: require('../../static/images/placeholder/template-jp.png'),
        text: true
      },
      {
        name: 'magazine-love-psb',
        label: '愛情',
        banner: require('../../static/images/placeholder/template-love.jpeg'),
        text: true
      },
      {
        name: 'magazine-travel2-psb',
        label: '旅行2',
        banner: require('../../static/images/placeholder/template-travel-2.png'),
        text: true
      }
    ],
    albumStyle: [
      {
        name: 'a4-child-psb',
        label: '親親寶貝',
        banner: 'https://static.xinshu.me/media/upload/3b0476f2dedb4736a0508b2730571d59!320'
      },
      {
        name: 'a4-child2-psb',
        label: '親子時光',
        banner: 'https://static.xinshu.me/media/upload/2f0d027ced064543ad9e14051181e801!320'
      },
      {
        name: 'a4-family-psb',
        label: '全家福',
        banner: 'https://static.xinshu.me/media/upload/82473eaad80744d182fe819604646440!320'
      },
      {
        name: 'a4-love-psb',
        label: '甜蜜愛情',
        banner: 'https://static.xinshu.me/media/upload/a7d2435e1ba64f1282e3c917e210b294!320'
      },
      {
        name: 'a4-magazine-psb',
        label: '雜誌風',
        banner: 'https://static.xinshu.me/media/upload/ca4c6c96fa014e448ddf9362d02a6be7!320'
      },
      {
        name: 'a4-simple-psb',
        label: '極簡主義',
        banner: 'https://static.xinshu.me/media/upload/f8477d0ca4c54c3294399e6cd8d54431!320'
      }
    ],
    templateStyle: 'magazine',
    activeType: 'leather',
    activeStyle: 'magazine-new',
    templateInfo: {},
    templatePages: [],
    templateLoaded: false,
    isCreating: false,
    banner: ''
  }),
  computed: {
    title () {
      if (this.templateStyle === 'traditional') {
        return '月曆'
      }
      return this.templateInfo.title
    },
    designType () {
      if (!this.templateLoaded) return
      const { styles, defaultDesignType } = this.templateInfo
      const template = find(styles, {
        style: this.activeStyle
      })
      if (!template) return defaultDesignType
      return template.designType
    },
    templateList () {
      if (this.templateStyle === 'traditional') {
        return [
          {
            name: '2020-h-traditional',
            label: '橫款',
            banner: require('../../static/images/product/calendar-5.png')
          },
          {
            name: '2020-v-traditional',
            label: '豎款',
            banner: require('../../static/images/product/swipe-calendar-v.jpg')
          }
        ]
      }
      return [
        {
          name: 'leather',
          label: '皮質'
        },
        {
          name: 'fabric',
          label: '布藝'
        }
      ]
    }
  },
  created () {
    const { binding, type } = this.$route.query
    this.templateStyle = type || 'magazine'
    if (binding) {
      this.activeType = binding
      const activeTemplate = this.templateList.filter(item => item.name === binding)
      this.banner = activeTemplate[0].banner
    }
    if (this.templateStyle === 'album') {
      this.activeStyle = 'a4-child-psb'
      this.templateStyles = this.albumStyle
    }
    this.fetchMagazineStyle(this.templateStyle)
  },
  methods: {
    ...mapActions(['setLoginShow']),
    setActiveType (item) {
      if (this.activeType === item.name) return
      this.activeType = item.name
      const activeTemplate = this.templateList.filter(template => template.name === item.name)
      this.banner = activeTemplate[0].banner
      if (this.templateStyle === 'magazine') {
        this.$refs.pages.scrollTop = 0
      }
    },
    async changeTemplateStyle (item) {
      this.activeStyle = item.name
      if (this.templateStyle === 'magazine' || this.templateStyle === 'album') {
        const pages = find(this.templateInfo.styles, { style: item.name })
        const templatePages = await this.fetchPages(pages.pageTemplates)
        this.templatePages = templatePages
      }
    },
    fetchPages (pages) {
      const promises = []
      const template = /^magazine/.test(this.activeStyle) ? this.activeStyle.replace('-psb', '-yx') : this.activeStyle.replace('-psb', '')
      for (const page of pages) {
        promises.push(api.fetchMagazinePage(`${template}.${page.name}`))
      }
      this.templateLoaded = false
      return Promise.all(promises).then(data => {
        this.templateLoaded = true
        return data
      })
    },
    // 预览排版
    fetchMagazineStyle (template) {
      api.fetchMagazineTemplates().then(async data => {
        const target = find(data, { name: template })
        if (target) {
          this.templateInfo = target
          const pages = find(target.styles, { style: this.activeStyle })
          const templatePages = await this.fetchPages(pages.pageTemplates)
          this.templatePages = templatePages
          this.templateLoaded = true
        }
      })
    },
    // 创建相片书
    createBook () {
      if (!this.$store.state.user.uid) {
        this.setLoginShow(true)
        return
      }
      this.isCreating = true
      let params = {
        template: this.templateStyle,
        style: this.templateStyle === 'magazine' || this.templateStyle === 'album' ? this.activeStyle : this.activeType
      }
      if (this.activeType === 'leather' && this.templateStyle !== 'traditional') {
        params = Object.assign(params, {
          material: 'leather',
          designTemplate: 'cover-magazine-2.leather'
        })
      }
      const requestHandler = {
        magazine: api.createPhotoBook,
        album: api.createPhotoBook,
        traditional: api.createCalendar
      }
      if (this.templateStyle === 'album') {
        delete params.material
        delete params.designTemplate
      }
      const request = requestHandler[this.templateStyle]
      request(params).then(data => {
        const { bid } = data
        this.isCreating = false
        if (bid) {
          this.isCreating = false
          const bookType = /magazine|album/.test(this.templateStyle) ? 'photobook' : 'calendar'
          window.location.href = `${window.location.origin}/app/design/album/?aid=${bid}&type=${bookType}`
        } else {
          this.$toast({
            message: '創建失敗'
          })
        }
      })
    }
  }
}
</script>

<style lang="scss">
.product-creation {
  max-width: 640px;
  margin: 2rem auto;
  border-radius: 6px;
  border: 1px solid #eee;
  padding: .75rem;
  overflow: hidden;
  &-previewer {
    position: absolute;
    top:0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow: auto;
    background: {
      repeat: no-repeat;
      size: cover;
    };
    .pages-wrapper {
      display: flex;
      flex: 1;
      flex-wrap: wrap;
      .page {
        display: flex;
        flex: 0 0 50%;
        padding: .5em;
        .page-content {
          background-color: #fff;
          border: 1px solid #eee;
          box-shadow: 0 0 10px 5px rgba(0,0,0,.05);
          &.cover {
            height: 100%;
            width: 100%;
            background: {
              repeat: no-repeat;
              size: 100% 100%;
            };
          }
        }
      }
    }
  }
  .title {
    font-size: 20px;
    font-weight: 500;
  }
  .btns {
    margin-bottom: .5rem;
    .btn + .btn {
      margin-left: .5rem;
    }
  }
  p {
    color: #666;
    font-size: 13px;
  }
  .title-meta {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: #fff;
    z-index: 30;
  }
  .banner {
    background: {
      color: #f7f7f7;
      repeat: no-repeat;
      size: cover;
    };
    margin: -0.75rem;
    margin-bottom: 1rem;
    position: relative;
    .holder {
      padding-bottom: 70%;
    }
  }
  .cover-holder {
    overflow: hidden;
  }
  .action {
    margin-top: .75rem;
  }
  .template-styles {
    display: flex;
    flex-wrap: wrap;
    &-banner {
      width: 80px;
    }
    &-item {
      display: inline-block;
      margin-right: 1em;
    }
    p{
      margin: .75em 0;
    }
    .tag {
      position: absolute;
      top: 0;
      left: 0;
      background-color: red;
      color: #fff;
      font-size: 13px;
      text-align: center;
      width: 4.2em;
      line-height: 1.2;
      margin-top: 2em;
      transform: rotate(-45deg);
      transform-origin: 0 bottom;
    }
  }
}
@media (max-width: 768px) {
  .product-creation {
    margin: 1rem;
  }
}
</style>
