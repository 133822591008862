<template>
  <section class="agent-intro-section">
    <h4>{{title}}</h4>
    <slot/>
  </section>
</template>

<script>
export default {
  name: '',
  props: {
    title: {
      type: String,
      default: ''
    }
  },
  data: () => ({
  }),
  computed: {},
  created () {},
  methods: {}
}
</script>

<style lang="scss" scoped>
.agent-intro-section {
  border-radius: 8px;
  box-shadow: 0 12px 40px 0 rgba(175,175,175,0.2);
  text-align: center;
  position: relative;
  padding-top: 4.5rem;
  padding-bottom: 2.5rem;
  padding-left: 2rem;
  padding-right: 2rem;
  margin-top: 4rem;
  h4 {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: .75rem 2.5rem;
    font-size: 1.75rem;
    background-color: #FFE2E3;
    color: #F56368;
    display: inline-block;
  }
  .item-icon {
    width: 90px;
    margin-bottom: 1rem;
  }
  .item-title {
    font-size: 1.25rem;
    font-weight: 500;
    color: #333;
    margin-bottom: 10px;
  }
  .item-desc {
    line-height: 1.5rem;
    font-size: 0.95rem;
    color: rgba(0,0,0,.5);
    &.fixed {
      min-height: 5rem;
      max-height: 5rem;
    }
  }
}
</style>
