<template>
  <div class="selection">
    <div class="row">
      <div class="col-xs-7 col-xs-offset-2">
        <div class="importer" role="button" v-if="showImportAction" @click="showImportModal = true">
          <i class="fa fa-facebook-official"></i>
          {{$t('selection.importBtn')}}
        </div>
        <div class="sub-nav">
          <div :class="['sub-nav__item', {'active': tab.name === tabName}]"
               v-for="(tab, index) in tabs" :key="index"
               @click="setTabItem(tab.name)">
            <i :class="['fa', `fa-${tab.icon}`]" aria-hidden="true"></i>
            {{tab.label}}
          </div>
        </div>
      </div>
    </div>
    <div class="text-center loading" v-if="!bookLoaded">
      <p>
        <i class="fa fa-spinner fa-spin"></i>
      </p>
      <p>{{$t('selection.tipLoading')}}</p>
    </div>
    <div class="row selection__content" v-else>
      <div class="col-xs-2">
        <div class="side-timeline" v-show="tabName === 'facebook' || tabName === 'patch'">
          <div :class="['time_year', {'active': currentYear === year}]" @click="setActiveYear(year)"
               v-for="(year, index) in currentYears" :key="index">{{year}}
          </div>
        </div>
      </div>
      <div class="col-xs-7">
        <div class="fresher" v-if="!isInitialized" @click="freshBookInfo" role="button">
          <b>{{$t('selection.loadingContentFetching')}}</b>
          {{$t('selection.loadingButtonFresh')}}
        </div>

        <template v-if="currentYearItems && currentYearItems.length">
          <action-item v-for="(item, index) in currentYearItems"
                       @select="toggleSelect(item, index)"
                       :itemType="tabName"
                       :isSimpleView="item.isSimpleView"
                       :info="item"
                       :key="index"/>
        </template>

        <template v-else>
          <div class="text-center" style="font-size: 1.25em;">{{$t('selection.tipEmpty')}}</div>
        </template>

        <div class="text-center mt-1"
             v-if="tabName === 'instagram' || (!currentYearItems.length && tabName !== 'patch')">
          <div>匯入更多數據</div>
          <a class="text-primary" :href="authLink" target="_blank">點擊授權</a>
        </div>
      </div>
      <div class="col-xs-2 selection__actions">
        <button class="btn primary" @click="saveLeave()">
          <i class="fa fa-eye" aria-hidden="true"></i>
          {{$t('selection.buttonSave')}}
        </button>
        <button class="btn plant" @click="showModal = true">
          <i class="fa fa-plus-circle" aria-hidden="true"></i>
          {{$t('selection.buttonPatch')}}
        </button>
        <router-link :to="{name: 'cover', query: $route.query}">
          <button class="btn secondary" style="margin-top: .5em;">
            <i class="fa fa-tachometer" aria-hidden="true"></i>
            {{$t('preview.buttonDesign')}}
          </button>
        </router-link>
      </div>
      <modal :show="showModal" class="patch-modal">
        <patch-item ref="patch" slot="body"></patch-item>
        <div class="clearfix" slot="footer">
          <button class="btn plant pull-left" @click="showModal = false">{{$t('button.cancel')}}</button>
          <button class="btn primary pull-right" :disabled="patchSaving" @click="savePatchItem">
            <i class="fa fa-spinner fa-spin" v-show="patchSaving"/>
            {{$t('button.ok')}}
          </button>
        </div>
      </modal>

      <modal :show="showImportModal" @close="showImportModal = false">
        <content-import is-step="step"
                        @close="showImportModal = false"
                        @select="selectSource"
                        slot="body"/>
      </modal>
    </div>
  </div>
</template>

<script>
import api from 'src/api'
import { isEmpty, orderBy } from 'lodash'
import ActionItem from 'src/components/layout/ActionItem.vue'
import PatchItem from './PatchItem.vue'
import ContentImport from '../../Shelf/ContentImport'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'selection',
  components: {
    ActionItem,
    PatchItem,
    ContentImport
  },
  data: () => ({
    tabName: 'facebook',
    years: [],
    insAccounts: [],
    patchYears: [],
    bookSource: {}, // 来源数据
    activeYear: '',
    activeInsYear: '',
    activePatchYear: '',
    bookInfo: {},
    showModal: false,
    modifies: new Map(),
    saving: false,
    dataLoaded: {
      facebook: false,
      patch: false,
      pages: false,
      albums: false,
      instagram: false
    },
    contentItems: [],
    isAuthed: false,
    showImportModal: false,
    showImportAction: false,
    patchSaving: false
  }),
  computed: {
    ...mapGetters(['user']),
    tabs () {
      return [
        {
          icon: 'facebook-official',
          name: 'facebook',
          label: this.$t('selection.tabFacebook')
        },
        {
          icon: 'facebook-official',
          name: 'albums',
          label: this.$t('selection.tabAlbums')
        },
        {
          icon: 'flag',
          name: 'pages',
          label: this.$t('selection.tabPages')
        },
        {
          icon: 'instagram',
          name: 'instagram',
          label: 'Instagram'
        },
        {
          icon: 'pencil-square',
          name: 'patch',
          label: this.$t('selection.tabPatch')
        }
      ]
    },
    authLink () {
      const bid = this.$route.query.bid
      const { uid } = this.user

      // 使用对象映射定义路由配置
      const routeMap = {
        instagram: '/social/instagram_data',
        pages: '/social/facebook_business',
        facebook: '/social/facebook_data',
        albums: '/social/facebook_data'
      }

      // 构建基础参数
      const baseParams = `bid=${bid}&uid=${uid}`

      // 获取路由路径
      const path = routeMap[this.tabName]

      // 如果是facebook或albums，需要添加source参数
      const sourceParam = ['facebook', 'albums'].includes(this.tabName) ? '&source=posts' : ''

      return `${path}?${baseParams}${sourceParam}`
    },
    currentYearItems () {
      if (!this.bookLoaded) {
        return []
      }

      // 如果是 facebook，按年份获取数据
      if (this.tabName === 'facebook') {
        return this.bookInfo[this.tabName][this.activeYear] || []
      }

      // 如果是 instagram，返回ins账号列表
      if (this.tabName === 'instagram') {
        return this.bookInfo[this.tabName] || []
      }

      // patch 特殊处理
      if (this.tabName === 'patch') {
        return isEmpty(this.bookInfo.patch) ? [] : this.bookInfo.patch[this.activePatchYear]
      }

      // 默认返回整个分类数据
      return this.bookInfo[this.tabName] || []
    },
    currentYears () {
      if (!this.bookLoaded) return []
      if (this.tabName === 'facebook') {
        return this.years
      }
      if (this.tabName === 'instagram') {
        return this.insAccounts
      }
      return this.patchYears
    },
    currentYear () {
      if (!this.bookLoaded) {
        return ''
      }
      if (this.tabName === 'patch') {
        return this.activePatchYear
      }
      return this.activeYear
    },
    bookLoaded () {
      return this.dataLoaded[this.tabName]
    },
    isAuth () {
      if (!this.dataLoaded[this.tabName]) return false
      const source = this.bookSource[this.tabName]
      if (!source) {
        return false
      } else {
        const {
          isGettingPosts,
          isGettingPages,
          postsInitialized,
          pagesInitialized,
          insInitialized,
          isGettingIns
        } = source
        switch (this.tabName) {
          case 'facebook':
          case 'albums':
            return isGettingPosts || postsInitialized
          case 'instagram':
            return isGettingIns || insInitialized
          case 'patch':
            return true
          case 'pages':
            return isGettingPages || pagesInitialized
          default:
            return true
        }
      }
    },
    isInitialized () {
      if (!this.bookLoaded) return true
      const source = this.bookSource[this.tabName]
      if (!source) {
        return true
      } else {
        const {
          isGettingPosts,
          isGettingPages,
          isGettingIns
        } = source
        switch (this.tabName) {
          case 'facebook':
          case 'albums':
            return !isGettingPosts
          case 'instagram':
            return !isGettingIns
          case 'patch':
            return true
          case 'pages':
            return !isGettingPages
          default:
            return false
        }
      }
    }
  },
  created () {
    const { sid } = this.$route.query
    this.tabName = this.$route.query.tab || 'facebook'
    this.fetchActiveInfo(sid, this.tabName)
  },
  beforeRouteLeave (to, from, next) {
    if (!this.saving) {
      this.saveContent(true)
    }
    next()
  },
  methods: {
    ...mapActions(['setLoading']),
    // 请求素材库内容
    async fetchActiveInfo (sourceId, tabName, force = false) {
      if (this.dataLoaded[tabName] && !force) {
        return
      }
      this.dataLoaded[tabName] = false
      const data = await api.fetchSocialInfo(sourceId, tabName)
      if (!data || data.errors) {
        const msg = data.errors ? `<br>錯誤碼 - ${data.errors.code}` : ''
        this.$toast({
          message: this.$t('selection.tipContentFetching', { msg }),
          position: 'bottom'
        })
        return
      }
      const social = this.resolveActiveItems(data, tabName)
      const {
        year,
        tab
      } = this.$route.query
      const {
        isGettingPosts,
        isGettingPages,
        isGettingIns,

        postsInitialized,
        pagesInitialized,
        insInitialized
      } = data
      this.bookSource[tabName] = data
      // 是否显示导入操作按钮
      this.showImportAction = !isGettingPages && !isGettingPosts && !postsInitialized && !pagesInitialized &&
        !insInitialized && !isGettingIns
      switch (tabName) {
        case 'facebook':
        case 'albums':
          this.isAuthed = isGettingPosts || postsInitialized
          break
        case 'patch':
          this.isAuthed = true
          break
        case 'pages':
          this.isAuthed = isGettingPages || pagesInitialized
          break
        case 'instagram':
          this.isAuthed = isGettingIns || insInitialized
          break
      }

      if (tabName === 'facebook') {
        this.years = social.years
        this.activeYear = (!tab || tab === 'facebook') && year ? year : social.activeYear
        this.bookInfo[tabName] = social.timeLine
        this.contentItems = this.bookInfo[tabName][this.activeYear] || []
      } else if (tabName === 'instagram') {
        this.insAccounts = social.data
        this.bookInfo[tabName] = social.data
        this.contentItems = this.bookInfo[tabName] || []
      } else if (tabName === 'patch') {
        this.patchYears = social.years
        this.activePatchYear = tab === 'patch' && year ? year : social.activeYear
        this.bookInfo[tabName] = social.timeLine
        this.contentItems = isEmpty(this.bookInfo.patch) ? [] : this.bookInfo.patch[this.activePatchYear]
      } else {
        this.bookInfo[tabName] = social.data
        this.contentItems = this.bookInfo[tabName] || []
      }
      // 状态加载放置最后
      this.dataLoaded[tabName] = true
    },
    // 刷新书籍社交内容
    freshBookInfo () {
      // 设置所有 dataLoaded 状态为 false
      Object.keys(this.dataLoaded).forEach(item => {
        this.dataLoaded[item] = false
        this.bookInfo[item] = []
      })
      // 更新当前 tab 栏内容
      this.setTabItem(this.tabName)
    },
    // 切换社交内容
    setTabItem (tab) {
      this.tabName = tab
      const query = {
        tab: tab,
        bid: this.$route.query.bid,
        sid: this.$route.query.sid
      }
      if (isEmpty(this.bookInfo[tab]) && !this.dataLoaded[tab]) {
        this.fetchActiveInfo(query.sid, tab)
      } else if (tab === this.tabName) {
        this.fetchActiveInfo(query.sid, tab, true)
        return
      } else {
        if (tab === 'facebook') {
          const year = this.activeYear
          this.contentItems = this.bookInfo[tab][year] || []
        } else if (tab === 'patch') {
          this.contentItems = isEmpty(this.bookInfo.patch) ? [] : this.bookInfo.patch[this.activePatchYear]
        } else {
          this.contentItems = this.bookInfo[tab]
        }
      }
      this.saveContent()
      this.$router.push({
        path: this.$route.path,
        query
      })
    },
    setActiveYear (year) {
      if (this.tabName === 'facebook') {
        this.activeYear = year
        this.contentItems = this.bookInfo[this.tabName][year]
      } else {
        this.activePatchYear = year
        this.contentItems = this.bookInfo.patch[year]
      }
      const query = Object.assign({}, this.$route.query, { year })
      this.$router.push({
        path: this.$route.path,
        query
      })
    },
    toggleSelect (item, index) {
      if (!item.checked && !item.selected) {
        this.$toast({ message: this.$t('selection.tipRecover') })
        return
      }
      this.$set(this.currentYearItems[index], 'checked', !item.checked)
      this.modifies.set(item.time || item.albumId, item.checked)
    },
    // 保存补写内容
    async savePatchItem () {
      if (this.patchSaving) {
        return
      }

      // 解构表单数据
      const {
        content,
        title,
        date,
        time,
        images
      } = this.$refs.patch
      const picList = images.map(item => item[0])

      // 验证内容和图片
      if (!content.trim() && !picList.length) {
        this.$toast({ message: this.$t('selection.tipCompletedPatch') })
        return
      }

      // 验证日期格式
      if (!date.split('-')[0].match(/^\d{4}$/)) {
        this.$toast({ message: this.$t('selection.tipTimeError') })
        return
      }

      // 构建提交数据
      const item = {
        title,
        content,
        postDate: `${date}T${time}`,
        picList
      }
      const { sid } = this.$route.query

      try {
        this.patchSaving = true
        // 显示保存中提示
        this.$toast({
          message: this.$t('selection.tipSaving'),
          duration: 1000,
          position: 'bottom'
        })

        await api.createItemContent(sid, item)

        // 保存成功提示
        this.$toast({
          message: this.$t('selection.tipSaved'),
          duration: 1500,
          icon: 'fa-check-circle'
        })

        // 重置表单并刷新数据
        this.$refs.patch.content = ''
        this.$refs.patch.images = []
        this.fetchActiveInfo(sid, 'patch', true)
      } finally {
        this.showModal = false
        this.patchSaving = false
      }
    },
    resolveActiveItems (data, tab) {
      if (tab === 'pages') {
        const pages = data.pages.map(item => {
          item.id = item.pageId
          item.photos = [item.picture]
          item.checked = true
          item.selected = true
          item.isSimpleView = true
          return item
        })
        return { data: pages }
      }

      if (tab === 'instagram') {
        const results = data.instagram.map(item => {
          item.id = item.insId
          item.photos = [item.picture]
          item.checked = true
          item.selected = true
          item.isSimpleView = true
          return item
        })
        return { data: results }
      }

      const timeLine = {}
      let years = []
      let activeYear = ''
      const checked = data[tab].checked.map(item => {
        item.checked = true
        return item
      })
      const unchecked = data[tab].unchecked.map(item => {
        item.checked = false
        return item
      })
      data[tab] = checked.concat(unchecked)
      switch (tab) {
        case 'facebook':
        case 'patch':
          data[tab] = orderBy(checked.concat(unchecked), ['month'], ['asc'])
          data[tab].forEach(item => {
            const year = item.month.substr(0, 4)
            const month = item.month.substr(4, 2)
            timeLine[year] = timeLine[year] || []
            timeLine[year].push(Object.assign(
              item,
              {
                month,
                time: item.month
              }
            ))
          })
          years = Object.keys(timeLine).reverse()
          activeYear = years[0]
          return {
            timeLine,
            activeYear,
            years,
            data: data[tab]
          }
        default:
          return {
            timeLine,
            activeYear,
            years,
            data: data[tab]
          }
      }
    },
    async saveContent () {
      const add = Array.from(this.modifies).map(([k, v]) => v && k).filter(Boolean)
      const remove = Array.from(this.modifies).map(([k, v]) => !v && k).filter(Boolean)
      if (!add.length && !remove.length) {
        return
      }
      if (this.saving) {
        return
      }
      try {
        this.saving = true
        const data = {
          [this.tabName]: {
            add,
            remove
          }
        }
        this.modifies = new Map()
        return await api.updateSocialBook(this.$route.query.sid, data)
      } finally {
        this.saving = false
      }
    },
    async saveLeave () {
      this.$toast({
        message: this.$t('selection.tipUpdating'),
        duration: 1000
      })
      await this.saveContent()
      const query = Object.assign(this.$route.query)
      delete query.tab
      this.$toast({
        message: this.$t('selection.tipUpdated'),
        icon: 'fa'
      })
      this.saving = true
      this.$router.push({
        name: 'preview',
        query
      })
    },
    // 选择社交内容来源
    selectSource (sources) {
      this.showImportModal = false
      this.setLoading(true)
      const { bid } = this.$route.query
      const { uid } = this.user
      let facebookImportLink = `/social/facebook_data?uid=${uid}&bid=${bid}`
      facebookImportLink = sources.reduce((total, item) => {
        total = `${total}&source=${item}`
        return total
      }, facebookImportLink)
      window.location.href = facebookImportLink
    }
  }
}
</script>

<style lang="scss">
.selection {
  margin-top: 2rem;

  .selection__content {
    margin: 1rem auto;

    .action-item + .action-item {
      margin-top: 1rem;
    }
  }

  .loading {
    i {
      font-size: 2rem;
    }

    p {
      margin: 1rem auto;
    }
  }

  .selection__actions {
    .btn {
      min-width: 120px;
    }

    .btn + .btn {
      margin-top: 1rem;
    }
  }

  .sidebar__timeline {
    overflow: auto;
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-end;
  }

  .time_year {
    height: 54px;
    line-height: 54px;
    width: 100%;
    max-width: 100px;
    text-align: center;
    color: #999;
    font-weight: 600;
    background-color: #f9f9f9;
    cursor: pointer;

    &.active {
      border-left: 4px solid $el-color-primary;
      background-color: #fff;
    }
  }

  .sub-nav {
    display: flex;

    .sub-nav__item {
      width: 100%;
      text-align: center;
      border-bottom: 2px solid #dfdfdf;
      cursor: pointer;
      padding: 4px;

      &.active {
        color: $el-color-primary;
        border-color: $el-color-primary;
      }
    }
  }

  .patch-modal {
    .modal-content {
      width: 600px;
      max-width: 600px;
    }
  }

  .importer {
    background-color: $color-text-fb;
    color: white;
    min-height: 2.5rem;
    line-height: 2.5rem;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    margin-bottom: 1em;
    border: solid 1px #c5d9e1;

    i.fa {
      font-size: 1.5em;
      margin-right: .5em;
    }
  }

  .fresher {
    background-color: #e1f5fe;
    color: #006390;
    text-align: center;
    min-height: 3rem;
    line-height: 3rem;
    border: 1px solid #c5d9e1;
    border-radius: 4px;
    font-size: 14px;
    margin-bottom: 1rem;
    cursor: pointer;
  }
}
</style>
