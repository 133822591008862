<template>
  <div class="pay-methods form-group">
    <label>
      {{$t('checkout.labelMethod')}}
    </label>
    <template>
      <div>
        <label :class="{active: mode === 'stripe'}">
          <input type="radio" name="method" value="stripe"
                 @change="labelChange" v-model="mode" >
          {{$t('checkout.labelMethodStripe')}}
        </label>
        <p class="method-desc">
          <img src="../../../../static/images/payment/visa-curved-128px.png" alt="visa">
          <img src="../../../../static/images/payment/american-express-curved-128px.png" alt="american">
          <img src="../../../../static/images/payment/mastercard-curved-128px.png" alt="mastercard">
        </p>
        <p class="text-mini text-orange text-italic">
        </p>
      </div>
    </template>
    <div>
      <label :class="{active: mode === 'paypal'}">
        <input type="radio" name="method" value="paypal"
               @change="labelChange" v-model="mode">
        {{$t('checkout.labelMethodPayPal')}}
      </label>
      <p class="method-desc">
        <img src="../../../../static/images/payment/paypal-curved-128px.png" alt="Paypal">
      </p>
    </div>
    <div v-if="false">
      <label :class="{active: mode === 'alipay'}">
        <input type="radio" name="method" value="alipay"
               @change="labelChange">
        {{$t('trade.order.alipayCheck')}}
      </label>
      <p class="method-desc">
        <img src="../../../../static/images/payment/alipay.png" alt="alipay">
      </p>
    </div>
    <i-dialog :show="false" @close="dialogShow = false" class="paypal-tip">
      <div class="content">
        <img src="../../../../static/images/payment/Paypal-tip.png" alt="">
        <div class="pay-btn">
          <button class="btn" @click="dialogShow = false">使用信用卡或借記卡付款</button>
        </div>
        <div class="pay-btn-tip">
          點擊使用信用卡快捷支付
          <p>
            <i class="fa fa-arrow-down"></i>
          </p>
        </div>
      </div>
    </i-dialog>
  </div>
</template>

<script>
export default {
  name: 'pay-methods',
  props: {
    method: String
  },
  data () {
    return {
      mode: '',
      dialogShow: false
    }
  },
  created () {
    this.mode = this.method
  },
  methods: {
    labelChange (e) {
      this.$emit('change', e.target.value)
    }
  }
}
</script>

<style lang="scss">

  .pay-methods {
    p.method-desc {
      padding-left: 1rem;
      margin-top: 6px;
    }
    img {
      width: 3rem;
    }
    img + img {
      margin-left: 10px;
    }
    .paypal-tip {
      img {
        width: 100%;
      }
      .modal-content {
        box-shadow: none;
      }
      .modal-body {
        padding: 0;
        border-radius: 4px;
      }
      .modal-footer {
        display: none;
      }
      .content {
        position: relative;
        &:before {
         content: '';
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          background-color: rgba(0,0,0,.3);
        }
      }
      .pay-btn-tip, .pay-btn {
        position: absolute;
        left: 0;
        text-align: center;
        width: 100%;
        font-weight: 500;
      }
      .pay-btn-tip {
        color: white;
        font-size: 30px;
        bottom: 5rem;
        text-shadow:  0 0 16px rgba(54,54,54,.65);
      }
      .pay-btn {
        bottom: 2rem;
        button {
          background-color: #e0e8ec;
          font-weight: 600;
          box-shadow: 0 0 36px rgba(54,54,54,.65);
        }
      }
      @media (max-width: 767px) {
        .pay-btn-tip {
          font-size: 18px;
          bottom: 4rem;
        }
        .pay-btn button {
          padding: 10px 20px;
        }
      }
      @media (min-width: 768px) {
        .pay-btn button {
          font-size: 18px;
          padding-top: 12px;
          padding-bottom: 12px;
          width: 60%;
        }
      }
    }
  }
</style>
