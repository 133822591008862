<template>
  <div class="cover" :style="style" :class="coverClass">
    <cover-holder :src="coverUrl" :height="height" radius="0"/>
  </div>
</template>

<script>
import CoverHolder from './CoverHolder'
export default {
  name: 'Cover',
  components: { CoverHolder },
  props: {
    book: Object
  },
  computed: {
    style () {
      return {
        paddingTop: this.filling / 2 + '%',
        paddingBottom: this.filling / 2 + '%'
      }
    },
    coverClass () {
      const classes = []
      if (this.book && this.book.type) {
        classes.push(this.book.type)
      }
      return classes.join(' ')
    },
    coverUrl () {
      return this.book.coverUrl
    },
    filling () {
      const height = parseFloat(this.height)
      return 141.4 - height
    },
    height () {
      let { type, typeset, templateStyle } = this.book
      typeset && (typeset = typeset.toLowerCase())
      if (type === 'calendar') {
        return templateStyle.includes('-v') ? 133.3 : 72.8
      }
      if (type === 'photobook') {
        if (templateStyle.startsWith('a4')) {
          return 135.7
        }
        return 77
      }
      if (typeset.startsWith('a4')) {
        return 135.7
      }
      return 141.4
    }
  },
  created () {},
  methods: {}
}
</script>

<style lang="scss">
.cover {
  &.calendar-h, &.calendar-v, &.calendar {
    .cover-holder {
      &:before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 5%;
        right: 5%;
        background-image: url(../../static/images/placeholder/ring.png);
        background-repeat: round;
        background-size: contain;
        background-position: 40% 50%;
        margin-top: -2.5%;
        padding-bottom: 5%;
        z-index: 1;
      }
    }
  }
}
</style>
