<template>
  <div class="select-group">
    <div class="row text-center">
      <div v-for="(sub, index) in choices" :key="index"
           class="col col-xs-6"
           @click="choice = sub.name">
        <button class="btn btn-block" :class="[choice === sub.name ? 'default-ghost' : 'plant']">
          {{sub.label}}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SKUSelector',
  model: {
    props: {
      prop: 'value',
      event: 'input'
    }
  },
  props: {
    choices: {
      type: [Array],
      required: true
    },
    value: {
      required: true
    }
  },
  data: () => ({
    choice: ''
  }),
  computed: {},
  watch: {
    choice (val) {
      this.$emit('input', val)
    },
    value (val) {
      this.choice = val
    }
  },
  created () {
    this.choice = this.value
  },
  methods: {}
}
</script>

<style lang="scss">

</style>
