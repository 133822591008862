<template>
  <div class="product-banner">
    <div class="container">
      <div class="row">
        <div class="product-banner-swipers col col-sm-8">
          <div id="product-banner-swipe">
            <div class="swiper-wrapper">
              <div v-for="(item, index) in bannerList" :key="index" class="swiper-slide">
                <img :src="item.image" alt="">
              </div>
            </div>
          </div>
          <div id="product-banner-swipe-thumb">
            <div class="swiper-wrapper">
              <div v-for="(item, index) in bannerList" :key="index" class="swiper-slide">
                <img :src="item.thumbImage" alt="">
              </div>
            </div>
          </div>
        </div>
        <div class="product-banner-intro col-sm-4">
          <sku :prices="prices" ref="sku" @check="checkPrice"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from 'swiper/js/swiper.min'
import 'swiper/css/swiper.min.css'
import Sku from './SKU'
export default {
  name: 'ProductBanner',
  components: { Sku },
  props: {
    prices: Object
  },
  data: () => ({
  }),
  computed: {
    bannerList () {
      switch (this.$route.query.type) {
        case 'calendar':
          return [
            {
              image: require('../../../static/images/product/calendar-1.png'),
              thumbImage: require('../../../static/images/product/calendar-1-thumb.png')
            },
            {
              image: require('../../../static/images/product/calendar-2.png'),
              thumbImage: require('../../../static/images/product/calendar-2-thumb.png')
            },
            {
              image: require('../../../static/images/product/calendar-3.png'),
              thumbImage: require('../../../static/images/product/calendar-3-thumb.png')
            },
            {
              image: require('../../../static/images/product/calendar-4.png'),
              thumbImage: require('../../../static/images/product/calendar-4-thumb.png')
            },
            {
              image: require('../../../static/images/product/calendar-5.png'),
              thumbImage: require('../../../static/images/product/calendar-5-thumb.png')
            }
          ]
        case 'photobook':
          if (this.$route.query.template === 'album') {
            return [
              {
                image: 'https://static.xinshu.me/media/upload/8dde62b3f4c84e45b23f6a23e2613f24!800',
                thumbImage: 'https://static.xinshu.me/media/upload/8dde62b3f4c84e45b23f6a23e2613f24!800'
              },
              {
                image: 'https://static.xinshu.me/media/upload/40d79d5229b844cea9b26b44d7866fa1!800',
                thumbImage: 'https://static.xinshu.me/media/upload/40d79d5229b844cea9b26b44d7866fa1!320'
              },
              {
                image: 'https://static.xinshu.me/media/upload/d91775ae8f104eaab681bc32c3459da2!800',
                thumbImage: 'https://static.xinshu.me/media/upload/d91775ae8f104eaab681bc32c3459da2!800'
              },
              {
                image: 'https://static.xinshu.me/media/upload/3c1f174af1114c8f92c0fb9637371d46!800',
                thumbImage: 'https://static.xinshu.me/media/upload/3c1f174af1114c8f92c0fb9637371d46!800'
              },
              {
                image: 'https://static.xinshu.me/media/upload/8dde62b3f4c84e45b23f6a23e2613f24!800',
                thumbImage: 'https://static.xinshu.me/media/upload/8dde62b3f4c84e45b23f6a23e2613f24!800'
              }
            ]
          }
          return [
            {
              image: require('../../../static/images/product/swiper-2.jpg'),
              thumbImage: require('../../../static/images/product/swiper-2-thumb.jpg')
            },
            {
              image: require('../../../static/images/product/photobook-1.png'),
              thumbImage: require('../../../static/images/product/photobook-1-thumb.png')
            },
            {
              image: require('../../../static/images/product/photobook-2.png'),
              thumbImage: require('../../../static/images/product/photobook-2-thumb.png')
            },
            {
              image: require('../../../static/images/product/photobook-3.png'),
              thumbImage: require('../../../static/images/product/photobook-3-thumb.png')
            },
            {
              image: require('../../../static/images/product/photobook-5.png'),
              thumbImage: require('../../../static/images/product/photobook-5-thumb.png')
            }
          ]
        case 'diarybook':
        default:
          return [
            {
              image: require('../../../static/images/product/diarybook-5.png'),
              thumbImage: require('../../../static/images/product/diarybook-5-thumb.png')
            },
            {
              image: require('../../../static/images/product/diarybook-4.png'),
              thumbImage: require('../../../static/images/product/diarybook-4-thumb.png')
            },
            {
              image: require('../../../static/images/product/diarybook-3.png'),
              thumbImage: require('../../../static/images/product/diarybook-3-thumb.png')
            },
            {
              image: require('../../../static/images/product/diarybook-2.png'),
              thumbImage: require('../../../static/images/product/diarybook-2-thumb.png')
            },
            {
              image: require('../../../static/images/product/diarybook-1.png'),
              thumbImage: require('../../../static/images/product/diarybook-1-thumb.png')
            }
          ]
      }
    }
  },
  created () {
    this.$nextTick(() => {
      /* eslint-disable */
      const galleryThumbs = new Swiper('#product-banner-swipe-thumb', {
        spaceBetween: 10,
        slidesPerView: 5,
        freeMode: true,
        watchSlidesVisibility: true,
        watchSlidesProgress: true,
      })
      const mySwiper = new Swiper('#product-banner-swipe', {
        spaceBetween: 50,
        lazy: true,
        thumbs: {
          swiper: galleryThumbs
        }
      })
    })
  },
  mounted () {
  },
  methods: {
    checkPrice () {
      this.$emit('check')
    }
  }
}
</script>

<style lang="scss">
.product-banner {
  position: relative;
  margin-top: 1rem;
  #product-banner-swipe {
    position: relative;
    overflow: hidden;
    &-thumb {
      position: relative;
      overflow: hidden;
      margin-top: .5rem;
      .swiper-slide {
        opacity: 0.5;
        transition: opacity .35s;
      }
      .swiper-slide-thumb-active {
        opacity: 1;
      }
    }
  }
}
</style>
